import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";
import { useForm } from "react-hook-form";
import { AgGridReact } from "ag-grid-react";
import ExtractChannelListFromTll from "../utils/ExtractChannelListFromTll";
import ExtractChannelListFromNewTll from "../utils/ExtractChannelListFromNewTll";
import ExportTll from "../utils/ExportTll";
import PcdXmlGenerate from "../utils/PcdXmlGenerate";
import Title from "../components/Title";
import LZString from "../utils/lz-string";

import "./tllConverter.css";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import TVConfig, {tvConfigDefault}  from "../components/TVConfig";
import ExtractTVConfig from "../utils/ExtractTVConfig";

const printResult = (res) => {
  console.log("---------------------------------------");
  if (res.add) {
    res.add.forEach(function (rowNode) {
      console.log("Added Row Node", rowNode);
    });
  }
  if (res.remove) {
    res.remove.forEach(function (rowNode) {
      console.log("Removed Row Node", rowNode);
    });
  }
  if (res.update) {
    res.update.forEach(function (rowNode) {
      console.log("Updated Row Node", rowNode);
    });
  }
};

const TllConverter = (props) => {
  // const [rowData, setRowData] = useState([]);
  // const [tllFileXmlData, setTllFileXmlData] = useState("");
  const [fileInfo, setFileInfo] = useState(JSON.parse(localStorage.getItem('fileInfo')) || {name : "No File", size : 0});
  const [xmlDocCache, setXmlDocCache] = useState(LZString.decompressFromUTF16(localStorage.getItem("xmlDocCache")) || "");
  const [tvConfig, setTvConfig] = useState(JSON.parse(localStorage.getItem('tvConfig')) || tvConfigDefault);
  const [channelInfoList, setChannelInfoList] = useState(JSON.parse(localStorage.getItem('channelInfoList')) || []);
  const [showTVConfig, setShowTVConfig] = useState(false);
  
  useEffect(() => {localStorage.setItem('fileInfo', JSON.stringify(fileInfo));}, [fileInfo]);
  useEffect(() => {
    try {
      //alert("Size of sample is: " + xmlDocCache.length);
      const compressed = LZString.compressToUTF16(xmlDocCache);
      //alert("Size of compressed sample is: " + compressed.length);
      localStorage.setItem("xmlDocCache",compressed);
    } catch (e) {
      alert(e.message);
    }
  }, [xmlDocCache]);
  useEffect(() => {localStorage.setItem('tvConfig', JSON.stringify(tvConfig));}, [tvConfig]);
  useEffect(() => {localStorage.setItem('channelInfoList', JSON.stringify(channelInfoList));}, [channelInfoList], showTVConfig);

  const gridRef = useRef(null);
  // const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const { register, handleSubmit } = useForm();

  /*** File IO ***/
  const onTLLFileOpen = (data) => {
    const file1 = data.tll[0];
    //console.log(file1);
    if (!file1) {
    } else if ((file1.name.split(".").pop().toUpperCase() !== "TLL") & (file1.name.split(".").pop().toUpperCase() !== "XML")) {
      //console.log("invalid type");
      // this.setState({
      //   errorOpen: true,
      //   errorMessage: "Invalid file extention. Please choose TLL or XML file.",
      // });
      alert("Invalid file extention. Please choose TLL or XML file.");
    } else {
      //handleFileLoad(file1, this.handleFileResponse);
      //this.props.handleEntryMenu();
      const reader = new FileReader();

      reader.onload = (e) => {
        try {
          const fileObject = {
            name : file1.name,
            size : file1.size 
          }
          setFileInfo(fileObject);
          const parser = new DOMParser();
          // console.log(e.target.result);
          const xmlDoc = parser.parseFromString(e.target.result, "text/xml");
          const tvConfigTll = ExtractTVConfig(xmlDoc);
          setTvConfig(tvConfigTll);
          /* Process channel */
          let channelList = ExtractChannelListFromTll(xmlDoc);
          //console.log(channelList);
          if (channelList.length === 0) {
            channelList = ExtractChannelListFromNewTll(xmlDoc);
          }
          setChannelInfoList(channelList);
          setXmlDocCache(e.target.result);
          //response("success");
        } catch (err) {
          // console.log(err);
          //response("Clone file read error: " + err);
        }
      };
      reader.readAsText(file1);
    }
  };

  const onExportTll = useCallback(() => {
    if(gridRef.current!== null) {
      let updatedChannelInfoList = [];
      gridRef.current.api.forEachNode((node, index) => {
        //console.log(index + ": " + JSON.stringify(node.data));
        updatedChannelInfoList.push(node.data);
      });
      setChannelInfoList(updatedChannelInfoList);
      if(tvConfig.modelName=== '' || tvConfig.modelName==='Unknown') alert('Model Name is Unknown!')
      else ExportTll(xmlDocCache, tvConfig, updatedChannelInfoList);
    } else {
      if(tvConfig.modelName=== '' || tvConfig.modelName==='Unknown') alert('Model Name is Unknown!')
      else ExportTll(xmlDocCache, tvConfig, channelInfoList);
    }
  }, [xmlDocCache, tvConfig, channelInfoList]);

  const onExportXML = useCallback(() => {
    let updatedChannelInfoList = [];
    if(gridRef.current!== null) gridRef.current.api.forEachNode((node, index) => {
      //console.log(index + ": " + JSON.stringify(node.data));
      updatedChannelInfoList.push(node.data);
    });
    setChannelInfoList(updatedChannelInfoList);
    PcdXmlGenerate(updatedChannelInfoList, "PCD");
  }, []);
  
  const onClickChEdit = () => setShowTVConfig(false);
  const onClickTVConfig = () => {
    let updatedChannelInfoList = [];
    if(gridRef.current!== null) gridRef.current.api.forEachNode((node, index) => {
      //console.log(index + ": " + JSON.stringify(node.data));
      updatedChannelInfoList.push(node.data);
    });
    setChannelInfoList(updatedChannelInfoList);
    setShowTVConfig(true);
  }
  /*** TV config ***/
  const handleChangeConfig = (id, value) => {
    // const { id, value } = e.target;
    console.log(`TV config changed: ${id}: ${value}`);
    // if(id==="checkbox") {
    //   e.target.value = (e.target.value === 'on')? 'off' : 'on';
    //   console.log(`${e.target.type}: ${e.target.id} = ${e.target.value}`);
    // }
    setTvConfig(prevState => ({
        ...prevState,
        [id]: value
    }));
  };

  /*** Channel ***/
  const [columnDefs] = useState([
    { headerName: "Number", field: "number", minWidth: 70, maxWidth: 120,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      sortable: true, editable: true, rowDrag: true,
      cellStyle: { cursor: 'pointer' }
    },
    { headerName: "TV/Radio", field: 'tvRadio', minWidth: 70, maxWidth: 90, 
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: { values: ['TV', 'Radio'], },
    },
    { field: "name", maxWidth: 300, sortable: true  },
    { field: "type", maxWidth: 130, sortable: true,    
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: { values:  ["RF-Terrestrial", "RF-Cable", "Satellite", "IP-UDP", "IP-RTP"], }, 
      valueSetter: params => { if (["IP-UDP", "IP-RTP"].includes(params.oldValue) && ["IP-UDP", "IP-RTP"].includes(params.newValue)) {
        params.data.type = params.newValue;
        return true;
      } else return false;} 
    },
    { headerName: "Encrypt", field: "encrypt", minWidth: 70, maxWidth: 90, sortable: true, cellEditor: 'agSelectCellEditor', cellEditorParams: { values:  ["Free", "Scramble"] },},
    { headerName: "ServiceID", field: "service_id", minWidth: 70, maxWidth: 90 },
    { headerName: "IP", field: "ipAddr", minWidth: 100, maxWidth: 130  },
    //{ headerName: "IP", field: "ipAddr", minWidth: 70, maxWidth: 90, editable: (params) => (params.node.data.validation_type === 'NUMERIC') },
    { headerName: "Port", field: "ipPort", minWidth: 70, maxWidth: 70 },
    { headerName: "Freq(MHz)", field: "frequency", minWidth: 70, maxWidth: 90  },
    { headerName: "Symbol(KHz)", field: "symbolRate", minWidth: 100, maxWidth: 100  },
    { headerName: "Polar", field: "polarisation", minWidth: 70, maxWidth: 90  },
    // { field: "detail", minWidth: 400, maxWidth: 500, editable: true },
  ]);
  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 100,
      resizable: true,
      editable: true, 
      cellStyle: { cursor: 'pointer' }
    };
  }, []);

  const addChannel = useCallback((addIndex) => {
    // api and columnApi on the gridRef object
    const { api } = gridRef.current;
    // api's will be null before grid initialised
    if (api == null) {
      return;
    }
    // access the Grid API
    const res = api.applyTransaction({ add: [{ number: 9999, tvRadio: "TV", name: "No Name", type: "IP-UDP", encrypt: "Free" }] });
    printResult(res.add[0].rowIndex);
    api.ensureIndexVisible(res.add[0].rowIndex);
    api.setFocusedCell(res.add[0].rowIndex);
  }, []);

  const onQuickFilterChanged = useCallback(() => {
    gridRef.current.api.setQuickFilter(document.getElementById("quickFilter").value);
  }, []);

  const clearData = useCallback(() => {
    setFileInfo({name : "No File", size : 0});
    setXmlDocCache("");
    setTvConfig(tvConfigDefault);
    setChannelInfoList([]);
  }, []);

  const onRemoveSelected = useCallback(() => {
    const selectedData = gridRef.current.api.getSelectedRows();
    const res = gridRef.current.api.applyTransaction({ remove: selectedData });
    printResult(res);
  }, []);
  const onRenumbering = useCallback(() => {
    console.log('### api.forEachNode() ###');
    gridRef.current.api.forEachNode((node, index) => {
      //  console.log(index + ' -> ' + node.data.number + ' : ' + node.data.channelName);
        node.setDataValue('number', index+1);
    });
  }, []);

  return (
    <div className="tllConverter">
      <Title onNew={clearData} onOpen={handleSubmit(onTLLFileOpen)} onLogout={() => props.onLogout()} fileName={fileInfo.name} fileSize={fileInfo.size}/>
      <div className="tllMain">
        <div className="tllSidenav">
          <div className="tllSideMenu">
            <button onClick={clearData}>New</button>
            <form key={1} onChange={handleSubmit(onTLLFileOpen)}>
              <input {...register("tll")} type="file" id="file23" />
              <label htmlFor="file23">Open</label>
            </form>
            <button onClick={onClickChEdit}>Channel Edit</button>
            <button onClick={onClickTVConfig}>TV Configuration</button>
            <button onClick={onExportTll}>Save as TLL</button>
            <button onClick={onExportXML}>Convert to XML</button>
            <button>Help</button>
          </div>
          <div className="author">
            <p>Copyright 2022-2022 by LG Electronics. All Rights Reserved. TLL Converter is Powered by LG EU DSL</p>
          </div>
        </div>
        <div className="tllContent">
          {showTVConfig ? 
            <TVConfig tvConfig={tvConfig} handleChangeConfig={handleChangeConfig}/> :
          <div className="tllChannelEditor">
              <div className="tllSectionTitle">Channel List</div>
            <div className="tllChannelEditorSection">
              <div className="tllChToolbox">
                <button onClick={addChannel}>Add IPTV Channel</button>
                <button onClick={onRemoveSelected}>Remove Selected</button>
                <button onClick={onRenumbering}>Renumbering</button>
                <input type="text" onInput={onQuickFilterChanged} id="quickFilter" placeholder="search..." />
              </div>
              <div className="tllChEditorContainer">
                <div style={gridStyle} className="ag-theme-balham">
                  <AgGridReact
                    ref={gridRef}
                    rowData={channelInfoList}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    suppressRowClickSelection={true}
                    singleClickEdit={true}
                    stopEditingWhenCellsLoseFocus={true}
                    rowDragManaged={true}
                    animateRows={true}
                    rowSelection={"multiple"}
                    undoRedoCellEditing={true}
                    undoRedoCellEditingLimit={5}
                  ></AgGridReact>
                </div>
              </div>
            </div>
          </div>
          }
        </div>
      </div>
    </div>
  );
};

export default TllConverter;

// import uniqid from "uniqid";
import {ChannelNameParcer, HexVchName2VchName} from "./ChannelNameParcer";

const ExtractChannelListFromTll = (xmlDoc) => {
  const channelList = [];
  if (xmlDoc.getElementsByTagName("IPTV").length !== 0) {
    const iptvChList = Array.from(xmlDoc.getElementsByTagName("IPTV")[0].children);
    if (typeof iptvChList !== "undefined" && iptvChList.length > 0) {
      iptvChList.forEach((iptvCh, index) => {
        const iptvChannel = {};
        iptvChannel.id = index + 1000;
        iptvChannel.number = iptvCh.getElementsByTagName("logicalNum")[0].childNodes[0].nodeValue;
        iptvChannel.tvRadio = "TV";
        iptvChannel.type = iptvCh.getElementsByTagName("protocol")[0].childNodes[0].nodeValue === "0" ? "IP-UDP" : "IP-RTP";
        iptvChannel.ipAddr = iptvCh.getElementsByTagName("multicast_address")[0].childNodes[0].nodeValue;
        iptvChannel.ipPort = iptvCh.getElementsByTagName("port")[0].childNodes[0].nodeValue;
        iptvChannel.service_id = iptvCh.getElementsByTagName("serviceId")[0].childNodes[0].nodeValue;
        if (iptvCh.getElementsByTagName("forensic_tuning").length !== 0)
          iptvChannel.encrypt = Boolean(iptvCh.getElementsByTagName("forensic_tuning")[0].childNodes[0].nodeValue) === true ? "Scramble" : "Free";
        else iptvChannel.encrypt = "Free";
        // iptvChannel.detail = `IP:Port ${iptvChannel.ipAddr}:${iptvChannel.ipPort}, ServiceID: ${iptvChannel.service_id}`;
        iptvChannel.frequency = "N/A";
        iptvChannel.symbolRate = "N/A";
        iptvChannel.polarisation = "N/A";
        iptvChannel.fixed_vchName = (iptvCh.getElementsByTagName("fixed_vchName").length !== 0)? iptvCh.getElementsByTagName("fixed_vchName")[0].childNodes[0].nodeValue : '';
        iptvChannel.hexVchName = (iptvCh.getElementsByTagName("hexVchName")[0].childNodes.length !== 0)? iptvCh.getElementsByTagName("hexVchName")[0].childNodes[0].nodeValue : '';
        iptvChannel.vchName = (iptvCh.getElementsByTagName("vchName")[0].childNodes.length !== 0)? iptvCh.getElementsByTagName("vchName")[0].childNodes[0].nodeValue : '';
        iptvChannel.name = (iptvChannel.vchName !== '')? iptvChannel.vchName : (iptvChannel.hexVchName !== '')? HexVchName2VchName(iptvChannel.hexVchName) : 'No Name';
        channelList.push(iptvChannel);
      });
    }
  } else {
    console.log("No IPTV element in TLL ");
  }

  if (xmlDoc.getElementsByTagName("DTV").length !== 0) {
    const dtvChList = Array.from(xmlDoc.getElementsByTagName("DTV")[0].children);
    if (typeof dtvChList !== "undefined" && dtvChList.length > 0) {
      dtvChList.forEach((dtvCh, index) => {
        const specialData = Number(dtvCh.getElementsByTagName("special_data")[0].childNodes[0].nodeValue) & 31;
        let rfType =
          dtvCh.getElementsByTagName("usSatelliteHandle").length !== 0 && dtvCh.getElementsByTagName("usSatelliteHandle")[0].childNodes[0].nodeValue !== 0
            ? "Satellite"
            : "RF-Cable";
        switch (specialData) {
          case 1:
          case 2:
            rfType = "RF-Terrestrial";
            break;
          case 5:
          case 6:
          case 7:
            rfType = "RF-Cable";
            break;
          case 9:
          case 10:
          case 11:
            rfType = "Satellite";
            break;
          default:
        }

        const dtvChannel = {};
        // id: i,
        dtvChannel.id = index;
        dtvChannel.number = dtvCh.getElementsByTagName("prNum")[0].childNodes[0].nodeValue & 0x3fff;
        dtvChannel.name = ChannelNameParcer(dtvCh.getElementsByTagName("hexVchName"), dtvCh.getElementsByTagName("vchName"));
        dtvChannel.type = rfType;
        dtvChannel.minorNum = dtvCh.getElementsByTagName("minorNum")[0].childNodes[0].nodeValue;
        dtvChannel.original_network_id = dtvCh.getElementsByTagName("original_network_id")[0].childNodes[0].nodeValue;
        dtvChannel.transport_id = dtvCh.getElementsByTagName("transport_id")[0].childNodes[0].nodeValue;
        dtvChannel.network_id = dtvCh.getElementsByTagName("network_id")[0].childNodes[0].nodeValue;
        dtvChannel.service_id = dtvCh.getElementsByTagName("service_id")[0].childNodes[0].nodeValue;
        dtvChannel.physicalNum = dtvCh.getElementsByTagName("physicalNum")[0].childNodes[0].nodeValue;
        dtvChannel.sourceIndex = dtvCh.getElementsByTagName("sourceIndex")[0].childNodes[0].nodeValue;
        dtvChannel.serviceType = dtvCh.getElementsByTagName("serviceType")[0].childNodes[0].nodeValue;
        dtvChannel.tvRadio = Number(dtvChannel.serviceType) === 2 ? "Radio" : "TV";
        dtvChannel.special_data = dtvCh.getElementsByTagName("special_data")[0].childNodes[0].nodeValue;
        dtvChannel.frequency =
          rfType === "Satellite" ? dtvCh.getElementsByTagName("frequency")[0].childNodes[0].nodeValue : dtvCh.getElementsByTagName("frequency")[0].childNodes[0].nodeValue / 1000;
        dtvChannel.nitVersion = dtvCh.getElementsByTagName("nitVersion")[0].childNodes[0].nodeValue;
        dtvChannel.mapType = dtvCh.getElementsByTagName("mapType")[0].childNodes[0].nodeValue;
        dtvChannel.mapAttr = dtvCh.getElementsByTagName("mapAttr")[0].childNodes[0].nodeValue;
        dtvChannel.isInvisable = dtvCh.getElementsByTagName("isInvisable")[0].childNodes[0].nodeValue;
        dtvChannel.isBlocked = dtvCh.getElementsByTagName("isBlocked")[0].childNodes[0].nodeValue;
        dtvChannel.isSkipped = dtvCh.getElementsByTagName("isSkipped")[0].childNodes[0].nodeValue;
        dtvChannel.isNumUnSel = dtvCh.getElementsByTagName("isNumUnSel")[0].childNodes[0].nodeValue;
        dtvChannel.isDeleted = dtvCh.getElementsByTagName("isDeleted")[0].childNodes[0].nodeValue;
        dtvChannel.chNameByte = dtvCh.getElementsByTagName("chNameByte")[0].childNodes[0].nodeValue;
        dtvChannel.isDisabled = dtvCh.getElementsByTagName("isDisabled")[0].childNodes[0].nodeValue;
        if (dtvCh.getElementsByTagName("hexVchName").length !== 0) {
          dtvChannel.hexVchName = dtvCh.getElementsByTagName("hexVchName")[0].childNodes[0].nodeValue;
          dtvChannel.notConvertedLengthOfVchName = dtvCh.getElementsByTagName("notConvertedLengthOfVchName")[0].childNodes[0].nodeValue;
        }
        dtvChannel.vchName = (dtvCh.getElementsByTagName("vchName")[0].childNodes.length !== 0)? dtvCh.getElementsByTagName("vchName")[0].childNodes[0].nodeValue : '';
        dtvChannel.lengthOfVchName = dtvCh.getElementsByTagName("lengthOfVchName")[0].childNodes[0].nodeValue;
        dtvChannel.isUserSelCHNo = dtvCh.getElementsByTagName("isUserSelCHNo")[0].childNodes[0].nodeValue;
        if (dtvCh.getElementsByTagName("videoStreamType").length !== 0) dtvChannel.videoStreamType = dtvCh.getElementsByTagName("videoStreamType")[0].childNodes[0].nodeValue;

        //console.log(dtvCh.getElementsByTagName("isScrambled"));
        if (dtvCh.getElementsByTagName("programNo").length !== 0) dtvChannel.programNo = dtvCh.getElementsByTagName("programNo")[0].childNodes[0].nodeValue;
        if (dtvCh.getElementsByTagName("isScrambled").length !== 0) dtvChannel.isScrambled = dtvCh.getElementsByTagName("isScrambled")[0].childNodes[0].nodeValue;
        if (dtvCh.getElementsByTagName("forensic_tunning").length !== 0)
          dtvChannel.encrypt = dtvCh.getElementsByTagName("forensic_tunning")[0].childNodes[0].nodeValue !== "0" ? "Scramble" : "Free";
        else dtvChannel.encrypt = "Free";

        // if (dtvCh.getElementsByTagName("logoIndex").length !== 0) dtvChannel.logoIndex = dtvCh.getElementsByTagName("logoIndex")[0].childNodes[0].nodeValue;
        // if (dtvCh.getElementsByTagName("favoriteIdxH").length !== 0) {
        //   dtvChannel.favoriteIdxA = dtvCh.getElementsByTagName("favoriteIdxA")[0].childNodes[0].nodeValue;
        //   dtvChannel.favoriteIdxB = dtvCh.getElementsByTagName("favoriteIdxB")[0].childNodes[0].nodeValue;
        //   dtvChannel.favoriteIdxC = dtvCh.getElementsByTagName("favoriteIdxC")[0].childNodes[0].nodeValue;
        //   dtvChannel.favoriteIdxD = dtvCh.getElementsByTagName("favoriteIdxD")[0].childNodes[0].nodeValue;
        //   dtvChannel.favoriteIdxE = dtvCh.getElementsByTagName("favoriteIdxE")[0].childNodes[0].nodeValue;
        //   dtvChannel.favoriteIdxF = dtvCh.getElementsByTagName("favoriteIdxF")[0].childNodes[0].nodeValue;
        //   dtvChannel.favoriteIdxG = dtvCh.getElementsByTagName("favoriteIdxG")[0].childNodes[0].nodeValue;
        //   dtvChannel.favoriteIdxH = dtvCh.getElementsByTagName("favoriteIdxH")[0].childNodes[0].nodeValue;
        // }
        dtvChannel.ipAddr = "N/A";
        dtvChannel.ipPort = "N/A";

        if (rfType === "Satellite") {
          dtvChannel.hSettingIDHandle = dtvCh.getElementsByTagName("hSettingIDHandle")[0].childNodes[0].nodeValue;
          dtvChannel.usSatelliteHandle = dtvCh.getElementsByTagName("usSatelliteHandle")[0].childNodes[0].nodeValue;
          const tbl = xmlDoc.getElementsByTagName("tbl" + dtvChannel.hSettingIDHandle)[0];
          if (typeof tbl !== "undefined") {
            const satHandle =
              tbl.getElementsByTagName("SatelliteHandle").length !== 0
                ? tbl.getElementsByTagName("SatelliteHandle")[0].textContent
                : tbl.getElementsByTagName("SatelliteHanlde")[0].textContent;
            if (satHandle === dtvChannel.usSatelliteHandle) {
              dtvChannel.satellite_id = (Number(dtvChannel.hSettingIDHandle) + 1).toString();
              const tplist = tbl.getElementsByTagName("TPList")[0].children;
              const tplistArray = Array.from(tplist).splice(1);
              const tprecord = tplistArray.find((data) => data.getElementsByTagName("TransponderId")[0].childNodes[0].nodeValue === dtvChannel.physicalNum);
              dtvChannel.symbolRate = typeof tprecord !== "undefined" ? tprecord.getElementsByTagName("SymbolRate")[0].childNodes[0].nodeValue : "0";
              dtvChannel.polarisation = typeof tprecord !== "undefined" ? (tprecord.getElementsByTagName("Polarisation")[0].childNodes[0].nodeValue === "0" ? "V" : "H") : "1";
            }
          }
          // dtvChannel.detail = `Freq: ${dtvChannel.frequency} MHz, Symbol: ${dtvChannel.symbolRate} KHz, Polar: ${dtvChannel.polarisation === "0" ? "V" : "H"}, ServiceID: ${
          //   dtvChannel.service_id
          // }`;
        } else {
          // dtvChannel.detail = `Freq: ${dtvChannel.frequency} MHz, ServiceID: ${dtvChannel.service_id}`;
          dtvChannel.symbolRate = "N/A";
          dtvChannel.polarisation = "N/A";
        }

        //console.log(dtvChannel.number);

        if (
          dtvChannel.number !== 0 &&
          dtvChannel.number !== "0" &&
          dtvChannel.isInvisable !== 1 &&
          dtvChannel.isDeleted !== 1 &&
          dtvChannel.isSkipped !== 1 &&
          dtvChannel.isBlocked !== 1
        ) {
          channelList.push(dtvChannel);
        }
      });
    }
  } else {
    console.log("No DTV element in TLL ");
  }
  return channelList;
};

export default ExtractChannelListFromTll;

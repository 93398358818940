import FileSaver from "file-saver";
import { countryOptions } from "../utils/Common";
import { dummyNewTll, dummyNewJson } from "../utils/Dummy";
//import React from "react";

const ExportNewTll = (xmlDocString, tvConfig, channelInfoList) => {
  // documentElement always represents the root node
  if (xmlDocString === "") {
    // throw new Error("Missing original TLL file");
    console.log("New file generation!");
  } 
  const xmlDoc = new DOMParser().parseFromString((xmlDocString === "")? dummyNewTll:xmlDocString, "text/xml");

  xmlDoc.getElementsByTagName("ModelName")[0].childNodes[0].nodeValue = tvConfig.modelName; // + "-" + tvInfo.modelName.suffix;
  xmlDoc.getElementsByTagName("BroadcastCountrySetting")[0].childNodes[0].nodeValue = tvConfig.country;

  /* Hotel mode */
  xmlDoc.getElementsByTagName("enableHotelMode")[0].childNodes[0].nodeValue = tvConfig.hotelMode; // off : on
  xmlDoc.getElementsByTagName("powerOnStatus")[0].childNodes[0].nodeValue = tvConfig.powerOnStatus; // stand_by : lst : power_on
  xmlDoc.getElementsByTagName("enablePowerOnDefaultVolume")[0].childNodes[0].nodeValue = tvConfig.enablePowerOnDefaultVolume; // off : on
  xmlDoc.getElementsByTagName("enableStartVolume")[0].childNodes[0].nodeValue = tvConfig.enableStartVolume; // off : on
  xmlDoc.getElementsByTagName("powerOnDefaultVolume")[0].childNodes[0].nodeValue = tvConfig.powerOnDefaultVolume; // 0 - 100
  xmlDoc.getElementsByTagName("minimumVolume")[0].childNodes[0].nodeValue = tvConfig.minimumVolume; // 0 : 0 - 100
  xmlDoc.getElementsByTagName("maximumVolume")[0].childNodes[0].nodeValue = tvConfig.maximumVolume; // 0: 0 - 100

  /* Key Management */
  xmlDoc.getElementsByTagName("keyManagement")[0].childNodes[0].nodeValue = tvConfig.keyManagement; // off : on
  xmlDoc.getElementsByTagName("enableIrRemote")[0].childNodes[0].nodeValue = tvConfig.enableIrRemote; // normal : usePwrOnly : blockAll
  xmlDoc.getElementsByTagName("enableLocalKey")[0].childNodes[0].nodeValue = tvConfig.enableLocalKey; // normal : usePwrOnly : blockAll

  /* Limited mode */
  xmlDoc.getElementsByTagName("enableLimitedMode")[0].childNodes[0].nodeValue = tvConfig.enableLimitedMode; // off : on  
  xmlDoc.getElementsByTagName("enterStationMenu")[0].childNodes[0].nodeValue = tvConfig.enterStationMenu; // off : on  
  xmlDoc.getElementsByTagName("enableProgramChange")[0].childNodes[0].nodeValue = tvConfig.enableProgramChange; // off : on  
  xmlDoc.getElementsByTagName("enableMenuKey")[0].childNodes[0].nodeValue = tvConfig.enableMenuKey; // off : on  
  xmlDoc.getElementsByTagName("enableOsdDisplay")[0].childNodes[0].nodeValue = tvConfig.enableOsdDisplay; // off : on  
  xmlDoc.getElementsByTagName("enableSystemProviderMode")[0].childNodes[0].nodeValue = tvConfig.enableSystemProviderMode; // off : on  
  
  /* Commercial Settings */
  xmlDoc.getElementsByTagName("instantOn")[0].childNodes[0].nodeValue = tvConfig.instantOn; // enable: disable
  xmlDoc.getElementsByTagName("acOn")[0].childNodes[0].nodeValue = tvConfig.acOn; // 0 (off) : 2 (reboot) : 3 (mute)
 
  const webOS50Json = (xmlDocString === "")? dummyNewJson:JSON.parse(xmlDoc.getElementsByTagName("legacybroadcast")[0].textContent);
  webOS50Json.modelInfo.country = countryOptions.find(({ value }) => value === tvConfig.country).label;

  webOS50Json.channelList = channelInfoList.map((ch) => {
    if (ch.type === "IP-UDP" || ch.type === "IP-RTP") {
      const iptvChannel = {
        bEncryptionch: ch.encrypt === "Scramble" ? true : false,
        protocolType: ch.type === "IP-UDP" ? "udp" : "rtp",
        ipAddress: ch.ipAddr,
        sourceAddress: "0.0.0.0",
        sourceIndex: "HIPTV",
        skipped: false,
        ipPort: Number(ch.ipPort),
        channelLogicalNum: Number(ch.number),
        fixedChannelName: false,
        SVCID: Number(ch.program) || 0,
        channelName: ch.name || "No Name",
      };
      return iptvChannel;
    } else {
      const dtvChannel = {
        //...defaultCh,
        ...ch,
        majorNumber: ch.tvRadio === "TV" ? Number(ch.number) : Number(ch.number) | 0x4000, //isNaN(ch.number) ? Number(ch.number) : ch.number,
        bEncryptionch: ch.encrypt === "Scramble" ? true : false,
        frequency: ch.type === "Satellite" ? ch.frequency : ch.frequency * 1000,
      };
      delete dtvChannel.id;
      delete dtvChannel.number;
      delete dtvChannel.name;
      delete dtvChannel.type;
      delete dtvChannel.tvRadio;
      delete dtvChannel.service_id;
      delete dtvChannel.encrypt;
      delete dtvChannel.ipAddr;
      delete dtvChannel.ipPort;
      delete dtvChannel.symbolRate;
      delete dtvChannel.polarisation;
      return dtvChannel;
    }
  });
  xmlDoc.getElementsByTagName("legacybroadcast")[0].textContent = JSON.stringify(webOS50Json);

  const xmlSerializer = new XMLSerializer();
  const xmlOutput = xmlSerializer.serializeToString(xmlDoc);
  // console.log("xmlOutput:", xmlOutput);
  const filename = (tvConfig.modelName.slice(0, 3) === 'STB')? `${tvConfig.modelName}_conv.tll` : `xx${tvConfig.modelName.replace(/^[0-9][0-9]/g, '')}_conv.tll`
  const file = new File([xmlOutput], filename, {
    type: "text/xml;charset=utf-8",
  });
  FileSaver.saveAs(file);
};

export default ExportNewTll;

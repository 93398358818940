const dummyOldTll = `<?xml version="1.0"?>
<TLLDATA>
    <ModelInfo>
        <ModelName type="0">55LV761H-ZA</ModelName>
        <CloneVersion type="1">
            <MajorVersion type="0">100</MajorVersion>
            <MinorVersion type="0">000</MinorVersion>
            <SatelliteDBVersion type="0">400</SatelliteDBVersion>
        </CloneVersion>
        <DTVInfo type="0">DTV_DVB</DTVInfo>
        <BroadcastCountrySetting type="0">GBR</BroadcastCountrySetting>
        <country type="0">EU</country>
    </ModelInfo>
    <UiRegistry>
        <hotelMode>
            <powerOnDefaultVolume type="0">0</powerOnDefaultVolume>
            <enableStartVolume type="0">off</enableStartVolume>
            <maximumVolume type="0">100</maximumVolume>
            <minimumVolume type="0">0</minimumVolume>
            <enableIrRemote type="0">normal</enableIrRemote>
            <enableLocalKey type="0">normal</enableLocalKey>
            <enterStationMenu type="0">on</enterStationMenu>
            <enableLimitedInputSrcChange type="0">on</enableLimitedInputSrcChange>
            <enableProgramChange type="0">on</enableProgramChange>
            <enableMenuKey type="0">on</enableMenuKey>
            <enableOsdDisplay type="0">on</enableOsdDisplay>
            <enableSystemProviderMode type="0">off</enableSystemProviderMode>
            <powerOnDefaultInput type="0">off</powerOnDefaultInput>
            <powerOnDefaultMajor type="0">2</powerOnDefaultMajor>
            <powerOnDefaultAv type="0">off</powerOnDefaultAv>
            <avSettings type="0">{}</avSettings>
            <powerOnAspectRatio type="0">disable</powerOnAspectRatio>
            <enableSetIdMenu type="0">off</enableSetIdMenu>
            <enableAuxSetting_av1 type="0">on</enableAuxSetting_av1>
            <enableAuxSetting_av2 type="0">on</enableAuxSetting_av2>
            <enableAuxSetting_av3 type="0">on</enableAuxSetting_av3>
            <enableAuxSetting_comp1 type="0">on</enableAuxSetting_comp1>
            <enableAuxSetting_comp2 type="0">on</enableAuxSetting_comp2>
            <enableAuxSetting_comp3 type="0">on</enableAuxSetting_comp3>
            <enableAuxSetting_rgb type="0">on</enableAuxSetting_rgb>
            <enableAuxSetting_hdmi1 type="0">on</enableAuxSetting_hdmi1>
            <enableAuxSetting_hdmi2 type="0">on</enableAuxSetting_hdmi2>
            <enableAuxSetting_hdmi3 type="0">on</enableAuxSetting_hdmi3>
            <enableAuxSetting_hdmi4 type="0">on</enableAuxSetting_hdmi4>
            <radioProgramStart type="0">1</radioProgramStart>
            <radioProgramCount type="0">1</radioProgramCount>
            <powerOnStatus type="0">stand_by</powerOnStatus>
            <keyManagement type="0">off</keyManagement>
            <enableLimitedMode type="0">off</enableLimitedMode>
            <enablePowerOnDefaultVolume type="0">off</enablePowerOnDefaultVolume>
            <enableChannelUpdate type="0">Auto</enableChannelUpdate>
            <enablePowerOnDefault type="0">off</enablePowerOnDefault>
            <auxSettingsEnabled type="0">off</auxSettingsEnabled>
            <noActivityPowerOffHours type="0">off</noActivityPowerOffHours>
            <displayRadioName type="0">off</displayRadioName>
            <blankOnRadioProgram type="0">off</blankOnRadioProgram>
            <tvlinkHcecHotelmode type="0">disable</tvlinkHcecHotelmode>
            <password type="0">1105</password>
            <enableHotelMode type="0">off</enableHotelMode>
        </hotelMode>
        <commercial>
            <instantOn type="0">enable</instantOn>
            <acOn type="0">0</acOn>
        </commercial>
        <support>
            <automaticUpdate type="0">off</automaticUpdate>
        </support>
    </UiRegistry>
    <CHANNEL>
        <ATV></ATV>
        <IPTV></IPTV>
        <DTV></DTV>
    </CHANNEL>
</TLLDATA>`;

const dummyNewTll = `<?xml version="1.0"?>
<TLLDATA>
    <ModelInfo>
        <ModelName type="0">43US662H0ZC</ModelName>
        <CloneVersion type="1">
            <MajorVersion type="0">200</MajorVersion>
            <MinorVersion type="0">000</MinorVersion>
            <SatelliteDBVersion type="0">500</SatelliteDBVersion>
        </CloneVersion>
        <DTVInfo type="0">DTV_DVB</DTVInfo>
        <BroadcastCountrySetting type="0">GBR</BroadcastCountrySetting>
        <country type="0">EU</country>
    </ModelInfo>
    <UiRegistry>
    <hotelMode>
       <powerOnDefaultVolume type="0">0</powerOnDefaultVolume>
       <enableStartVolume type="0">off</enableStartVolume>
       <maximumVolume type="0">100</maximumVolume>
       <minimumVolume type="0">0</minimumVolume>
       <enableIrRemote type="0">normal</enableIrRemote>
       <enableLocalKey type="0">normal</enableLocalKey>
       <enterStationMenu type="0">on</enterStationMenu>
       <enableLimitedInputSrcChange type="0">on</enableLimitedInputSrcChange>
       <enableProgramChange type="0">on</enableProgramChange>
       <enableMenuKey type="0">on</enableMenuKey>
       <enableOsdDisplay type="0">on</enableOsdDisplay>
       <enableSystemProviderMode type="0">off</enableSystemProviderMode>
       <powerOnDefaultInput type="0">off</powerOnDefaultInput>
       <powerOnDefaultMajor type="0">2</powerOnDefaultMajor>
       <powerOnDefaultAv type="0">off</powerOnDefaultAv>
       <avSettings type="0">{}</avSettings>
       <powerOnAspectRatio type="0">disable</powerOnAspectRatio>
       <enableSetIdMenu type="0">off</enableSetIdMenu>
       <enableAuxSetting_av1 type="0">on</enableAuxSetting_av1>
       <enableAuxSetting_av2 type="0">on</enableAuxSetting_av2>
       <enableAuxSetting_av3 type="0">on</enableAuxSetting_av3>
       <enableAuxSetting_comp1 type="0">on</enableAuxSetting_comp1>
       <enableAuxSetting_comp2 type="0">on</enableAuxSetting_comp2>
       <enableAuxSetting_comp3 type="0">on</enableAuxSetting_comp3>
       <enableAuxSetting_rgb type="0">on</enableAuxSetting_rgb>
       <enableAuxSetting_hdmi1 type="0">on</enableAuxSetting_hdmi1>
       <enableAuxSetting_hdmi2 type="0">on</enableAuxSetting_hdmi2>
       <enableAuxSetting_hdmi3 type="0">on</enableAuxSetting_hdmi3>
       <enableAuxSetting_hdmi4 type="0">on</enableAuxSetting_hdmi4>
       <radioProgramStart type="0">1</radioProgramStart>
       <radioProgramCount type="0">1</radioProgramCount>
       <powerOnStatus type="0">stand_by</powerOnStatus>
       <keyManagement type="0">off</keyManagement>
       <enableLimitedMode type="0">off</enableLimitedMode>
       <enablePowerOnDefaultVolume type="0">off</enablePowerOnDefaultVolume>
       <enableChannelUpdate type="0">Auto</enableChannelUpdate>
       <enablePowerOnDefault type="0">off</enablePowerOnDefault>
       <auxSettingsEnabled type="0">off</auxSettingsEnabled>
       <noActivityPowerOffHours type="0">off</noActivityPowerOffHours>
       <displayRadioName type="0">off</displayRadioName>
       <blankOnRadioProgram type="0">off</blankOnRadioProgram>
       <tvlinkHcecHotelmode type="0">disable</tvlinkHcecHotelmode>
       <password type="0">1105</password>
       <enableHotelMode type="0">off</enableHotelMode>
    </hotelMode>
    <commercial>
        <instantOn type="0">enable</instantOn>
        <acOn type="0">0</acOn>
    </commercial>
    <support>
       <automaticUpdate type="0">off</automaticUpdate>
    </support>
  </UiRegistry>
  <CHANNEL>
        <legacybroadcast></legacybroadcast>
        <gibbs />
    </CHANNEL>
</TLLDATA>`;

const dummyNewJson = {
    "modelInfo":{
       "country":"United Kingdom"
    },
    "tkgsConfigObj":{},
    "positionConfigObj":{
       "lastvalue":0,
       "longValue":0,
       "northSouthDir":false,
       "eastWestDir":false
    },
    "bouquetList":[],
    "settingIdList":[
       {
          "LNBFrequency1":9750,
          "Selected":true,
          "LNBName":"Universal",
          "PortL1":0,
          "LNBVoltage":true,
          "PortL2":0,
          "BlindSearch":false,
          "SatPosition":0,
          "SubUBFrequency":1420,
          "LNBPower":true,
          "MotorPos":0,
          "UBSlot":0,
          "MDUType":0,
          "NetworkSearch":false,
          "ConnectionL1":0,
          "ConnectionL2":0,
          "LNBTypeUniNor":false,
          "MotorizedSystem":0,
          "satelliteId":"0192",
          "UBFrequency":1210,
          "ScanSearchType":"ALL",
          "SubUBSlot":1,
          "FactoryDefault":true,
          "LNBType":0,
          "LNBFrequency2":10600
       }
    ],
    "channelList":[],
    "satelliteList":[
    ],
    "lcnStoreObj":{},
    "currActvStatObj": {
        "cmnData_CSGenreVersion": 0,
        "dvbRgnDepth": 0,
        "dvbRgnCountry": false,
        "dvbRgnPrimary": false,
        "isManualScanned": false,
        "cmnData_BSKeywordVersion": 0,
        "cmnData_BSFeatureVersion": 0,
        "isChFreqVerValid": false,
        "dvbRgnCountryCode": 0,
        "isDualVideo3DBroadcastEnabled": false,
        "cableNwRange_endFrequency": 466000,
        "chSettingValueForJP_isAntennaAudioLevelSet": false,
        "cmnData_TRGenreVersion": 0,
        "cmnData_LogoVersionList": [
            {
                "cmnData_BSLogoVersion": 0,
                "cmnData_CSLogoVersion": 0
            },
            {
                "cmnData_BSLogoVersion": 0,
                "cmnData_CSLogoVersion": 0
            },
            {
                "cmnData_BSLogoVersion": 0,
                "cmnData_CSLogoVersion": 0
            },
            {
                "cmnData_BSLogoVersion": 0,
                "cmnData_CSLogoVersion": 0
            },
            {
                "cmnData_BSLogoVersion": 0,
                "cmnData_CSLogoVersion": 0
            },
            {
                "cmnData_BSLogoVersion": 0,
                "cmnData_CSLogoVersion": 0
            }
        ],
        "cableNwHomeTP_frequency": 362000,
        "preferredNidSaved": 0,
        "cableNwSpec_isNetworkIdFilterOn": false,
        "isPowrOnlyMode": false,
        "cmnData_CSFeatureVersion": 0,
        "cmnData_TRKeywordVersion": 0,
        "cmnData_CSKeywordVersion": 0,
        "secondVideoPIDFor3DBroadcast": 0,
        "cableNwHomeTP_symbolRate": 6900,
        "dvbRgnSecondaryCode": 0,
        "dvbRgnTertiary": false,
        "scanMasks": 16383,
        "cmnData_TRFrequencyVersion": 0,
        "dvbRgnTertiaryCode": 0,
        "cableNwSpec_channelScanMode": 0,
        "cableNwSpec_isNetworkIdEnabled": false,
        "warmConflict": false,
        "premiumDtvOffsetForItaly": 0,
        "profileNameLengthForCIPlus": 0,
        "chFreqVersion": 0,
        "profileNameForCIPlusBase64": "",
        "cableNwRange_startFrequency": 338000,
        "currentCableSO": 9,
        "cableNwSpec_isNetworkUpdateOn": false,
        "premiumDataOffsetForItaly": 0,
        "preferredNid": 0,
        "isProfileNameUsedForCIPlus": false,
        "cableNwSpec_isSkipScramble": false,
        "cmnData_TRFeatureVersion": 0,
        "chSettingValueForJP_isSatelliteAudioLevelSet": false,
        "satellite2TunerConn": "MAIN",
        "lcnTableVersionForItaly": 0,
        "cableNwSpec_isAutoBilndScan": false,
        "chSettingValueForJP_isSatellitePowerSupplyOn": false,
        "cmnData_BSGenreVersion": 0,
        "lcnCRCVersionForItaly": 0,
        "currTerrestrialSO": false,
        "cableNwHomeTP_networkID": 0,
        "autoScanPopUpAgain": false,
        "cableNwHomeTP_constellation": 8,
        "dvbRgnSecondary": false,
        "isVisualImpairedServiceEnabled": false,
        "isTerrestrialSkipScramble": false
    },
    "satelliteList": [
        {
            "tpListLoad": false,
            "angleToGo": "65.65E",
            "TransponderList": [],
            "satelliteName": "THOR 0.8W",
            "factoryDefault": true,
            "deleteFlag": false,
            "satelliteId": "3592",
            "satLocation": "0.8W"
        },
        {
            "tpListLoad": false,
            "angleToGo": "65.65E",
            "TransponderList": [],
            "satelliteName": "AMOS 4.0W",
            "factoryDefault": true,
            "deleteFlag": false,
            "satelliteId": "3560",
            "satLocation": "4.0W"
        },
        {
            "tpListLoad": false,
            "angleToGo": "65.65E",
            "TransponderList": [],
            "satelliteName": "SES ASTRA 5.0E",
            "factoryDefault": true,
            "deleteFlag": false,
            "satelliteId": "0050",
            "satLocation": "5.0E"
        },
        {
            "tpListLoad": false,
            "angleToGo": "65.65E",
            "TransponderList": [],
            "satelliteName": "EUTELSAT 5A 5.0W",
            "factoryDefault": true,
            "deleteFlag": false,
            "satelliteId": "3550",
            "satLocation": "5.0W"
        },
        {
            "tpListLoad": false,
            "angleToGo": "65.65E",
            "TransponderList": [],
            "satelliteName": "EUTELSAT 7 A/B 7.0E",
            "factoryDefault": true,
            "deleteFlag": false,
            "satelliteId": "0070",
            "satLocation": "7.0E"
        },
        {
            "tpListLoad": false,
            "angleToGo": "65.65E",
            "TransponderList": [],
            "satelliteName": "NILESAT 7.0W",
            "factoryDefault": true,
            "deleteFlag": false,
            "satelliteId": "3530",
            "satLocation": "7.0W"
        },
        {
            "tpListLoad": false,
            "angleToGo": "65.65E",
            "TransponderList": [],
            "satelliteName": "EUTELSAT 8A 8.0W",
            "factoryDefault": true,
            "deleteFlag": false,
            "satelliteId": "3520",
            "satLocation": "8.0W"
        },
        {
            "tpListLoad": false,
            "angleToGo": "65.65E",
            "TransponderList": [],
            "satelliteName": "EUTELSAT 9A 9.0E",
            "factoryDefault": true,
            "deleteFlag": false,
            "satelliteId": "0090",
            "satLocation": "9.0E"
        },
        {
            "tpListLoad": false,
            "angleToGo": "65.65E",
            "TransponderList": [],
            "satelliteName": "EUTELSAT 10A 10.0E",
            "factoryDefault": true,
            "deleteFlag": false,
            "satelliteId": "0100",
            "satLocation": "10.0E"
        },
        {
            "tpListLoad": false,
            "angleToGo": "65.65E",
            "TransponderList": [],
            "satelliteName": "EUTELSAT HOTBIRD 13.0E",
            "factoryDefault": true,
            "deleteFlag": false,
            "satelliteId": "0130",
            "satLocation": "13.0E"
        },
        {
            "tpListLoad": false,
            "angleToGo": "65.65E",
            "TransponderList": [],
            "satelliteName": "EUTELSAT 16A 16.0E",
            "factoryDefault": true,
            "deleteFlag": false,
            "satelliteId": "0160",
            "satLocation": "16.0E"
        },
        {
            "tpListLoad": true,
            "angleToGo": "65.65E",
            "TransponderList": [
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 0,
                    "factoryDefault": true,
                    "polarization": "Horizontal",
                    "ONID": 0,
                    "codeRate": 4,
                    "satelliteId": "0192",
                    "symbolRate": 22000,
                    "frequency": 12604,
                    "homeTp": false,
                    "channelIdx": 0,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 0,
                    "factoryDefault": true,
                    "polarization": "Vertical",
                    "ONID": 0,
                    "codeRate": 4,
                    "satelliteId": "0192",
                    "symbolRate": 22000,
                    "frequency": 12552,
                    "homeTp": false,
                    "channelIdx": 1,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 1,
                    "factoryDefault": true,
                    "polarization": "Horizontal",
                    "ONID": 0,
                    "codeRate": 2,
                    "satelliteId": "0192",
                    "symbolRate": 22000,
                    "frequency": 10832,
                    "homeTp": false,
                    "channelIdx": 2,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 1,
                    "factoryDefault": true,
                    "polarization": "Horizontal",
                    "ONID": 0,
                    "codeRate": 9,
                    "satelliteId": "0192",
                    "symbolRate": 27500,
                    "frequency": 11798,
                    "homeTp": false,
                    "channelIdx": 3,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 1,
                    "factoryDefault": true,
                    "polarization": "Horizontal",
                    "ONID": 0,
                    "codeRate": 2,
                    "satelliteId": "0192",
                    "symbolRate": 22000,
                    "frequency": 11303,
                    "homeTp": false,
                    "channelIdx": 4,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 0,
                    "factoryDefault": true,
                    "polarization": "Horizontal",
                    "ONID": 0,
                    "codeRate": 4,
                    "satelliteId": "0192",
                    "symbolRate": 22000,
                    "frequency": 12692,
                    "homeTp": false,
                    "channelIdx": 5,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 1,
                    "factoryDefault": true,
                    "polarization": "Vertical",
                    "ONID": 0,
                    "codeRate": 2,
                    "satelliteId": "0192",
                    "symbolRate": 29700,
                    "frequency": 11856,
                    "homeTp": false,
                    "channelIdx": 6,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 1,
                    "factoryDefault": true,
                    "polarization": "Vertical",
                    "ONID": 0,
                    "codeRate": 2,
                    "satelliteId": "0192",
                    "symbolRate": 29700,
                    "frequency": 12402,
                    "homeTp": false,
                    "channelIdx": 7,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 1,
                    "factoryDefault": true,
                    "polarization": "Horizontal",
                    "ONID": 0,
                    "codeRate": 2,
                    "satelliteId": "0192",
                    "symbolRate": 30000,
                    "frequency": 12344,
                    "homeTp": false,
                    "channelIdx": 8,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 1,
                    "factoryDefault": true,
                    "polarization": "Horizontal",
                    "ONID": 0,
                    "codeRate": 2,
                    "satelliteId": "0192",
                    "symbolRate": 22000,
                    "frequency": 12722,
                    "homeTp": false,
                    "channelIdx": 9,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 0,
                    "factoryDefault": true,
                    "polarization": "Horizontal",
                    "ONID": 0,
                    "codeRate": 4,
                    "satelliteId": "0192",
                    "symbolRate": 22000,
                    "frequency": 12515,
                    "homeTp": false,
                    "channelIdx": 10,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 1,
                    "factoryDefault": true,
                    "polarization": "Horizontal",
                    "ONID": 0,
                    "codeRate": 2,
                    "satelliteId": "0192",
                    "symbolRate": 22000,
                    "frequency": 10891,
                    "homeTp": false,
                    "channelIdx": 11,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 1,
                    "factoryDefault": true,
                    "polarization": "Horizontal",
                    "ONID": 0,
                    "codeRate": 2,
                    "satelliteId": "0192",
                    "symbolRate": 22000,
                    "frequency": 11053,
                    "homeTp": false,
                    "channelIdx": 12,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 0,
                    "factoryDefault": true,
                    "polarization": "Horizontal",
                    "ONID": 0,
                    "codeRate": 4,
                    "satelliteId": "0192",
                    "symbolRate": 22000,
                    "frequency": 11244,
                    "homeTp": false,
                    "channelIdx": 13,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 1,
                    "factoryDefault": true,
                    "polarization": "Horizontal",
                    "ONID": 0,
                    "codeRate": 2,
                    "satelliteId": "0192",
                    "symbolRate": 22000,
                    "frequency": 11273,
                    "homeTp": false,
                    "channelIdx": 14,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 1,
                    "factoryDefault": true,
                    "polarization": "Vertical",
                    "ONID": 0,
                    "codeRate": 2,
                    "satelliteId": "0192",
                    "symbolRate": 22000,
                    "frequency": 11347,
                    "homeTp": false,
                    "channelIdx": 15,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 1,
                    "factoryDefault": true,
                    "polarization": "Horizontal",
                    "ONID": 0,
                    "codeRate": 2,
                    "satelliteId": "0192",
                    "symbolRate": 22000,
                    "frequency": 11494,
                    "homeTp": false,
                    "channelIdx": 16,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 1,
                    "factoryDefault": true,
                    "polarization": "Horizontal",
                    "ONID": 0,
                    "codeRate": 2,
                    "satelliteId": "0192",
                    "symbolRate": 22000,
                    "frequency": 11582,
                    "homeTp": false,
                    "channelIdx": 17,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 1,
                    "factoryDefault": true,
                    "polarization": "Horizontal",
                    "ONID": 0,
                    "codeRate": 2,
                    "satelliteId": "0192",
                    "symbolRate": 22000,
                    "frequency": 11362,
                    "homeTp": false,
                    "channelIdx": 18,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 1,
                    "factoryDefault": true,
                    "polarization": "Horizontal",
                    "ONID": 0,
                    "codeRate": 2,
                    "satelliteId": "0192",
                    "symbolRate": 22000,
                    "frequency": 11671,
                    "homeTp": false,
                    "channelIdx": 19,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 0,
                    "factoryDefault": true,
                    "polarization": "Vertical",
                    "ONID": 0,
                    "codeRate": 3,
                    "satelliteId": "0192",
                    "symbolRate": 27500,
                    "frequency": 12051,
                    "homeTp": false,
                    "channelIdx": 20,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 0,
                    "factoryDefault": true,
                    "polarization": "Horizontal",
                    "ONID": 0,
                    "codeRate": 3,
                    "satelliteId": "0192",
                    "symbolRate": 27500,
                    "frequency": 12148,
                    "homeTp": false,
                    "channelIdx": 21,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 0,
                    "factoryDefault": true,
                    "polarization": "Horizontal",
                    "ONID": 0,
                    "codeRate": 3,
                    "satelliteId": "0192",
                    "symbolRate": 27500,
                    "frequency": 12188,
                    "homeTp": false,
                    "channelIdx": 22,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 0,
                    "factoryDefault": true,
                    "polarization": "Horizontal",
                    "ONID": 0,
                    "codeRate": 3,
                    "satelliteId": "0192",
                    "symbolRate": 27500,
                    "frequency": 12226,
                    "homeTp": false,
                    "channelIdx": 23,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 0,
                    "factoryDefault": true,
                    "polarization": "Horizontal",
                    "ONID": 0,
                    "codeRate": 3,
                    "satelliteId": "0192",
                    "symbolRate": 27500,
                    "frequency": 12266,
                    "homeTp": false,
                    "channelIdx": 24,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 0,
                    "factoryDefault": true,
                    "polarization": "Horizontal",
                    "ONID": 0,
                    "codeRate": 3,
                    "satelliteId": "0192",
                    "symbolRate": 27500,
                    "frequency": 12422,
                    "homeTp": false,
                    "channelIdx": 25,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 0,
                    "factoryDefault": true,
                    "polarization": "Horizontal",
                    "ONID": 0,
                    "codeRate": 3,
                    "satelliteId": "0192",
                    "symbolRate": 27500,
                    "frequency": 12460,
                    "homeTp": false,
                    "channelIdx": 26,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 0,
                    "factoryDefault": true,
                    "polarization": "Vertical",
                    "ONID": 0,
                    "codeRate": 3,
                    "satelliteId": "0192",
                    "symbolRate": 27500,
                    "frequency": 12480,
                    "homeTp": false,
                    "channelIdx": 27,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 0,
                    "factoryDefault": true,
                    "polarization": "Horizontal",
                    "ONID": 0,
                    "codeRate": 4,
                    "satelliteId": "0192",
                    "symbolRate": 22000,
                    "frequency": 12663,
                    "homeTp": false,
                    "channelIdx": 28,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 1,
                    "factoryDefault": true,
                    "polarization": "Vertical",
                    "ONID": 0,
                    "codeRate": 2,
                    "satelliteId": "0192",
                    "symbolRate": 22000,
                    "frequency": 10729,
                    "homeTp": false,
                    "channelIdx": 29,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 0,
                    "factoryDefault": true,
                    "polarization": "Horizontal",
                    "ONID": 0,
                    "codeRate": 4,
                    "satelliteId": "0192",
                    "symbolRate": 22000,
                    "frequency": 10744,
                    "homeTp": false,
                    "channelIdx": 30,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 0,
                    "factoryDefault": true,
                    "polarization": "Vertical",
                    "ONID": 0,
                    "codeRate": 4,
                    "satelliteId": "0192",
                    "symbolRate": 22000,
                    "frequency": 10758,
                    "homeTp": false,
                    "channelIdx": 31,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 1,
                    "factoryDefault": true,
                    "polarization": "Horizontal",
                    "ONID": 0,
                    "codeRate": 3,
                    "satelliteId": "0192",
                    "symbolRate": 22000,
                    "frequency": 10773,
                    "homeTp": false,
                    "channelIdx": 32,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 0,
                    "factoryDefault": true,
                    "polarization": "Vertical",
                    "ONID": 0,
                    "codeRate": 4,
                    "satelliteId": "0192",
                    "symbolRate": 22000,
                    "frequency": 10788,
                    "homeTp": false,
                    "channelIdx": 33,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 1,
                    "factoryDefault": true,
                    "polarization": "Horizontal",
                    "ONID": 0,
                    "codeRate": 3,
                    "satelliteId": "0192",
                    "symbolRate": 22000,
                    "frequency": 10803,
                    "homeTp": false,
                    "channelIdx": 34,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 1,
                    "factoryDefault": true,
                    "polarization": "Vertical",
                    "ONID": 0,
                    "codeRate": 2,
                    "satelliteId": "0192",
                    "symbolRate": 22000,
                    "frequency": 10818,
                    "homeTp": false,
                    "channelIdx": 35,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 1,
                    "factoryDefault": true,
                    "polarization": "Horizontal",
                    "ONID": 0,
                    "codeRate": 2,
                    "satelliteId": "0192",
                    "symbolRate": 22000,
                    "frequency": 10714,
                    "homeTp": false,
                    "channelIdx": 36,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 0,
                    "factoryDefault": true,
                    "polarization": "Vertical",
                    "ONID": 0,
                    "codeRate": 4,
                    "satelliteId": "0192",
                    "symbolRate": 22000,
                    "frequency": 10847,
                    "homeTp": false,
                    "channelIdx": 37,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 1,
                    "factoryDefault": true,
                    "polarization": "Horizontal",
                    "ONID": 0,
                    "codeRate": 2,
                    "satelliteId": "0192",
                    "symbolRate": 23500,
                    "frequency": 10862,
                    "homeTp": false,
                    "channelIdx": 38,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 0,
                    "factoryDefault": true,
                    "polarization": "Vertical",
                    "ONID": 0,
                    "codeRate": 4,
                    "satelliteId": "0192",
                    "symbolRate": 22000,
                    "frequency": 10876,
                    "homeTp": false,
                    "channelIdx": 39,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 1,
                    "factoryDefault": true,
                    "polarization": "Vertical",
                    "ONID": 0,
                    "codeRate": 2,
                    "satelliteId": "0192",
                    "symbolRate": 22000,
                    "frequency": 10906,
                    "homeTp": false,
                    "channelIdx": 40,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 0,
                    "factoryDefault": true,
                    "polarization": "Horizontal",
                    "ONID": 0,
                    "codeRate": 5,
                    "satelliteId": "0192",
                    "symbolRate": 22000,
                    "frequency": 10921,
                    "homeTp": false,
                    "channelIdx": 41,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 1,
                    "factoryDefault": true,
                    "polarization": "Vertical",
                    "ONID": 0,
                    "codeRate": 2,
                    "satelliteId": "0192",
                    "symbolRate": 22000,
                    "frequency": 10936,
                    "homeTp": false,
                    "channelIdx": 42,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 1,
                    "factoryDefault": true,
                    "polarization": "Horizontal",
                    "ONID": 0,
                    "codeRate": 2,
                    "satelliteId": "0192",
                    "symbolRate": 22000,
                    "frequency": 10964,
                    "homeTp": false,
                    "channelIdx": 43,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 0,
                    "factoryDefault": true,
                    "polarization": "Vertical",
                    "ONID": 0,
                    "codeRate": 4,
                    "satelliteId": "0192",
                    "symbolRate": 22000,
                    "frequency": 10979,
                    "homeTp": false,
                    "channelIdx": 44,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 1,
                    "factoryDefault": true,
                    "polarization": "Horizontal",
                    "ONID": 0,
                    "codeRate": 4,
                    "satelliteId": "0192",
                    "symbolRate": 22000,
                    "frequency": 10994,
                    "homeTp": false,
                    "channelIdx": 45,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 1,
                    "factoryDefault": true,
                    "polarization": "Vertical",
                    "ONID": 0,
                    "codeRate": 1,
                    "satelliteId": "0192",
                    "symbolRate": 22000,
                    "frequency": 11008,
                    "homeTp": false,
                    "channelIdx": 46,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 1,
                    "factoryDefault": true,
                    "polarization": "Horizontal",
                    "ONID": 0,
                    "codeRate": 3,
                    "satelliteId": "0192",
                    "symbolRate": 23500,
                    "frequency": 11023,
                    "homeTp": false,
                    "channelIdx": 47,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 0,
                    "factoryDefault": true,
                    "polarization": "Vertical",
                    "ONID": 0,
                    "codeRate": 4,
                    "satelliteId": "0192",
                    "symbolRate": 22000,
                    "frequency": 11038,
                    "homeTp": false,
                    "channelIdx": 48,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 0,
                    "factoryDefault": true,
                    "polarization": "Vertical",
                    "ONID": 0,
                    "codeRate": 4,
                    "satelliteId": "0192",
                    "symbolRate": 22000,
                    "frequency": 11068,
                    "homeTp": false,
                    "channelIdx": 49,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 1,
                    "factoryDefault": true,
                    "polarization": "Horizontal",
                    "ONID": 0,
                    "codeRate": 3,
                    "satelliteId": "0192",
                    "symbolRate": 22000,
                    "frequency": 11082,
                    "homeTp": false,
                    "channelIdx": 50,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 0,
                    "factoryDefault": true,
                    "polarization": "Vertical",
                    "ONID": 0,
                    "codeRate": 4,
                    "satelliteId": "0192",
                    "symbolRate": 22000,
                    "frequency": 11097,
                    "homeTp": false,
                    "channelIdx": 51,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 1,
                    "factoryDefault": true,
                    "polarization": "Horizontal",
                    "ONID": 0,
                    "codeRate": 2,
                    "satelliteId": "0192",
                    "symbolRate": 22000,
                    "frequency": 11112,
                    "homeTp": false,
                    "channelIdx": 52,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 1,
                    "factoryDefault": true,
                    "polarization": "Vertical",
                    "ONID": 0,
                    "codeRate": 2,
                    "satelliteId": "0192",
                    "symbolRate": 22000,
                    "frequency": 11127,
                    "homeTp": false,
                    "channelIdx": 53,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 0,
                    "factoryDefault": true,
                    "polarization": "Horizontal",
                    "ONID": 0,
                    "codeRate": 4,
                    "satelliteId": "0192",
                    "symbolRate": 22000,
                    "frequency": 11141,
                    "homeTp": false,
                    "channelIdx": 54,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 0,
                    "factoryDefault": true,
                    "polarization": "Vertical",
                    "ONID": 0,
                    "codeRate": 4,
                    "satelliteId": "0192",
                    "symbolRate": 22000,
                    "frequency": 11156,
                    "homeTp": false,
                    "channelIdx": 55,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 1,
                    "factoryDefault": true,
                    "polarization": "Horizontal",
                    "ONID": 0,
                    "codeRate": 3,
                    "satelliteId": "0192",
                    "symbolRate": 22000,
                    "frequency": 11171,
                    "homeTp": false,
                    "channelIdx": 56,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 0,
                    "factoryDefault": true,
                    "polarization": "Vertical",
                    "ONID": 0,
                    "codeRate": 4,
                    "satelliteId": "0192",
                    "symbolRate": 22000,
                    "frequency": 11186,
                    "homeTp": false,
                    "channelIdx": 57,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 1,
                    "factoryDefault": true,
                    "polarization": "Horizontal",
                    "ONID": 0,
                    "codeRate": 2,
                    "satelliteId": "0192",
                    "symbolRate": 22000,
                    "frequency": 11214,
                    "homeTp": false,
                    "channelIdx": 58,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 1,
                    "factoryDefault": true,
                    "polarization": "Vertical",
                    "ONID": 0,
                    "codeRate": 2,
                    "satelliteId": "0192",
                    "symbolRate": 22000,
                    "frequency": 11229,
                    "homeTp": false,
                    "channelIdx": 59,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 1,
                    "factoryDefault": true,
                    "polarization": "Vertical",
                    "ONID": 0,
                    "codeRate": 2,
                    "satelliteId": "0192",
                    "symbolRate": 22000,
                    "frequency": 11259,
                    "homeTp": false,
                    "channelIdx": 60,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 1,
                    "factoryDefault": true,
                    "polarization": "Vertical",
                    "ONID": 0,
                    "codeRate": 2,
                    "satelliteId": "0192",
                    "symbolRate": 22000,
                    "frequency": 11288,
                    "homeTp": false,
                    "channelIdx": 61,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 0,
                    "factoryDefault": true,
                    "polarization": "Vertical",
                    "ONID": 0,
                    "codeRate": 4,
                    "satelliteId": "0192",
                    "symbolRate": 22000,
                    "frequency": 11318,
                    "homeTp": false,
                    "channelIdx": 62,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 1,
                    "factoryDefault": true,
                    "polarization": "Horizontal",
                    "ONID": 0,
                    "codeRate": 3,
                    "satelliteId": "0192",
                    "symbolRate": 22000,
                    "frequency": 11332,
                    "homeTp": false,
                    "channelIdx": 63,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 1,
                    "factoryDefault": true,
                    "polarization": "Vertical",
                    "ONID": 0,
                    "codeRate": 2,
                    "satelliteId": "0192",
                    "symbolRate": 22000,
                    "frequency": 11377,
                    "homeTp": false,
                    "channelIdx": 64,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 0,
                    "factoryDefault": true,
                    "polarization": "Horizontal",
                    "ONID": 0,
                    "codeRate": 4,
                    "satelliteId": "0192",
                    "symbolRate": 22000,
                    "frequency": 11391,
                    "homeTp": false,
                    "channelIdx": 65,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 1,
                    "factoryDefault": true,
                    "polarization": "Vertical",
                    "ONID": 0,
                    "codeRate": 2,
                    "satelliteId": "0192",
                    "symbolRate": 22000,
                    "frequency": 11406,
                    "homeTp": false,
                    "channelIdx": 66,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 0,
                    "factoryDefault": true,
                    "polarization": "Horizontal",
                    "ONID": 0,
                    "codeRate": 4,
                    "satelliteId": "0192",
                    "symbolRate": 22000,
                    "frequency": 11421,
                    "homeTp": false,
                    "channelIdx": 67,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 1,
                    "factoryDefault": true,
                    "polarization": "Vertical",
                    "ONID": 0,
                    "codeRate": 2,
                    "satelliteId": "0192",
                    "symbolRate": 22000,
                    "frequency": 11436,
                    "homeTp": false,
                    "channelIdx": 68,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 1,
                    "factoryDefault": true,
                    "polarization": "Horizontal",
                    "ONID": 0,
                    "codeRate": 2,
                    "satelliteId": "0192",
                    "symbolRate": 22000,
                    "frequency": 11464,
                    "homeTp": false,
                    "channelIdx": 69,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 1,
                    "factoryDefault": true,
                    "polarization": "Horizontal",
                    "ONID": 0,
                    "codeRate": 2,
                    "satelliteId": "0192",
                    "symbolRate": 22000,
                    "frequency": 11479,
                    "homeTp": false,
                    "channelIdx": 70,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 0,
                    "factoryDefault": true,
                    "polarization": "Vertical",
                    "ONID": 0,
                    "codeRate": 4,
                    "satelliteId": "0192",
                    "symbolRate": 22000,
                    "frequency": 11509,
                    "homeTp": false,
                    "channelIdx": 71,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 0,
                    "factoryDefault": true,
                    "polarization": "Horizontal",
                    "ONID": 0,
                    "codeRate": 4,
                    "satelliteId": "0192",
                    "symbolRate": 22000,
                    "frequency": 11523,
                    "homeTp": false,
                    "channelIdx": 72,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 0,
                    "factoryDefault": true,
                    "polarization": "Vertical",
                    "ONID": 0,
                    "codeRate": 4,
                    "satelliteId": "0192",
                    "symbolRate": 22000,
                    "frequency": 11538,
                    "homeTp": false,
                    "channelIdx": 73,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 1,
                    "factoryDefault": true,
                    "polarization": "Horizontal",
                    "ONID": 0,
                    "codeRate": 2,
                    "satelliteId": "0192",
                    "symbolRate": 22000,
                    "frequency": 11553,
                    "homeTp": false,
                    "channelIdx": 74,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 1,
                    "factoryDefault": true,
                    "polarization": "Vertical",
                    "ONID": 0,
                    "codeRate": 3,
                    "satelliteId": "0192",
                    "symbolRate": 23000,
                    "frequency": 11567,
                    "homeTp": false,
                    "channelIdx": 75,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 0,
                    "factoryDefault": true,
                    "polarization": "Vertical",
                    "ONID": 0,
                    "codeRate": 4,
                    "satelliteId": "0192",
                    "symbolRate": 22000,
                    "frequency": 11597,
                    "homeTp": false,
                    "channelIdx": 76,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 0,
                    "factoryDefault": true,
                    "polarization": "Horizontal",
                    "ONID": 0,
                    "codeRate": 4,
                    "satelliteId": "0192",
                    "symbolRate": 22000,
                    "frequency": 11612,
                    "homeTp": false,
                    "channelIdx": 77,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 0,
                    "factoryDefault": true,
                    "polarization": "Vertical",
                    "ONID": 0,
                    "codeRate": 4,
                    "satelliteId": "0192",
                    "symbolRate": 22000,
                    "frequency": 11627,
                    "homeTp": false,
                    "channelIdx": 78,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 0,
                    "factoryDefault": true,
                    "polarization": "Horizontal",
                    "ONID": 0,
                    "codeRate": 4,
                    "satelliteId": "0192",
                    "symbolRate": 22000,
                    "frequency": 11641,
                    "homeTp": false,
                    "channelIdx": 79,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 1,
                    "factoryDefault": true,
                    "polarization": "Vertical",
                    "ONID": 0,
                    "codeRate": 2,
                    "satelliteId": "0192",
                    "symbolRate": 22000,
                    "frequency": 11656,
                    "homeTp": false,
                    "channelIdx": 80,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 0,
                    "factoryDefault": true,
                    "polarization": "Vertical",
                    "ONID": 0,
                    "codeRate": 4,
                    "satelliteId": "0192",
                    "symbolRate": 22000,
                    "frequency": 11686,
                    "homeTp": false,
                    "channelIdx": 81,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 1,
                    "factoryDefault": true,
                    "polarization": "Horizontal",
                    "ONID": 0,
                    "codeRate": 9,
                    "satelliteId": "0192",
                    "symbolRate": 27500,
                    "frequency": 11720,
                    "homeTp": false,
                    "channelIdx": 82,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 0,
                    "factoryDefault": true,
                    "polarization": "Vertical",
                    "ONID": 0,
                    "codeRate": 3,
                    "satelliteId": "0192",
                    "symbolRate": 27500,
                    "frequency": 11739,
                    "homeTp": false,
                    "channelIdx": 83,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 1,
                    "factoryDefault": true,
                    "polarization": "Horizontal",
                    "ONID": 0,
                    "codeRate": 9,
                    "satelliteId": "0192",
                    "symbolRate": 27500,
                    "frequency": 11758,
                    "homeTp": false,
                    "channelIdx": 84,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 1,
                    "factoryDefault": true,
                    "polarization": "Vertical",
                    "ONID": 0,
                    "codeRate": 9,
                    "satelliteId": "0192",
                    "symbolRate": 29500,
                    "frequency": 11778,
                    "homeTp": false,
                    "channelIdx": 85,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 1,
                    "factoryDefault": true,
                    "polarization": "Vertical",
                    "ONID": 0,
                    "codeRate": 2,
                    "satelliteId": "0192",
                    "symbolRate": 29700,
                    "frequency": 11817,
                    "homeTp": false,
                    "channelIdx": 86,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 0,
                    "factoryDefault": true,
                    "polarization": "Horizontal",
                    "ONID": 0,
                    "codeRate": 3,
                    "satelliteId": "0192",
                    "symbolRate": 27500,
                    "frequency": 11836,
                    "homeTp": false,
                    "channelIdx": 87,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 1,
                    "factoryDefault": true,
                    "polarization": "Horizontal",
                    "ONID": 0,
                    "codeRate": 3,
                    "satelliteId": "0192",
                    "symbolRate": 27500,
                    "frequency": 11876,
                    "homeTp": false,
                    "channelIdx": 88,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 1,
                    "factoryDefault": true,
                    "polarization": "Vertical",
                    "ONID": 0,
                    "codeRate": 2,
                    "satelliteId": "0192",
                    "symbolRate": 29700,
                    "frequency": 11895,
                    "homeTp": false,
                    "channelIdx": 89,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 1,
                    "factoryDefault": true,
                    "polarization": "Horizontal",
                    "ONID": 0,
                    "codeRate": 9,
                    "satelliteId": "0192",
                    "symbolRate": 27500,
                    "frequency": 11914,
                    "homeTp": false,
                    "channelIdx": 90,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 1,
                    "factoryDefault": true,
                    "polarization": "Vertical",
                    "ONID": 0,
                    "codeRate": 2,
                    "satelliteId": "0192",
                    "symbolRate": 29700,
                    "frequency": 11934,
                    "homeTp": false,
                    "channelIdx": 91,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 0,
                    "factoryDefault": true,
                    "polarization": "Horizontal",
                    "ONID": 0,
                    "codeRate": 3,
                    "satelliteId": "0192",
                    "symbolRate": 27500,
                    "frequency": 11954,
                    "homeTp": false,
                    "channelIdx": 92,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 0,
                    "factoryDefault": true,
                    "polarization": "Vertical",
                    "ONID": 0,
                    "codeRate": 3,
                    "satelliteId": "0192",
                    "symbolRate": 27500,
                    "frequency": 11973,
                    "homeTp": false,
                    "channelIdx": 93,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 1,
                    "factoryDefault": true,
                    "polarization": "Horizontal",
                    "ONID": 0,
                    "codeRate": 9,
                    "satelliteId": "0192",
                    "symbolRate": 27500,
                    "frequency": 11992,
                    "homeTp": false,
                    "channelIdx": 94,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 1,
                    "factoryDefault": true,
                    "polarization": "Vertical",
                    "ONID": 0,
                    "codeRate": 2,
                    "satelliteId": "0192",
                    "symbolRate": 29700,
                    "frequency": 12012,
                    "homeTp": false,
                    "channelIdx": 95,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 1,
                    "factoryDefault": true,
                    "polarization": "Horizontal",
                    "ONID": 0,
                    "codeRate": 9,
                    "satelliteId": "0192",
                    "symbolRate": 27500,
                    "frequency": 12032,
                    "homeTp": false,
                    "channelIdx": 96,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 1,
                    "factoryDefault": true,
                    "polarization": "Horizontal",
                    "ONID": 0,
                    "codeRate": 9,
                    "satelliteId": "0192",
                    "symbolRate": 27500,
                    "frequency": 12070,
                    "homeTp": false,
                    "channelIdx": 97,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 1,
                    "factoryDefault": true,
                    "polarization": "Vertical",
                    "ONID": 0,
                    "codeRate": 2,
                    "satelliteId": "0192",
                    "symbolRate": 29700,
                    "frequency": 12090,
                    "homeTp": false,
                    "channelIdx": 98,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 0,
                    "factoryDefault": true,
                    "polarization": "Horizontal",
                    "ONID": 0,
                    "codeRate": 3,
                    "satelliteId": "0192",
                    "symbolRate": 27500,
                    "frequency": 12110,
                    "homeTp": false,
                    "channelIdx": 99,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 1,
                    "factoryDefault": true,
                    "polarization": "Vertical",
                    "ONID": 0,
                    "codeRate": 2,
                    "satelliteId": "0192",
                    "symbolRate": 29700,
                    "frequency": 12129,
                    "homeTp": false,
                    "channelIdx": 100,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 1,
                    "factoryDefault": true,
                    "polarization": "Vertical",
                    "ONID": 0,
                    "codeRate": 2,
                    "satelliteId": "0192",
                    "symbolRate": 29700,
                    "frequency": 12168,
                    "homeTp": false,
                    "channelIdx": 101,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 1,
                    "factoryDefault": true,
                    "polarization": "Vertical",
                    "ONID": 0,
                    "codeRate": 2,
                    "satelliteId": "0192",
                    "symbolRate": 29700,
                    "frequency": 12207,
                    "homeTp": false,
                    "channelIdx": 102,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 1,
                    "factoryDefault": true,
                    "polarization": "Vertical",
                    "ONID": 0,
                    "codeRate": 2,
                    "satelliteId": "0192",
                    "symbolRate": 29700,
                    "frequency": 12246,
                    "homeTp": false,
                    "channelIdx": 103,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 1,
                    "factoryDefault": true,
                    "polarization": "Vertical",
                    "ONID": 0,
                    "codeRate": 2,
                    "satelliteId": "0192",
                    "symbolRate": 29700,
                    "frequency": 12285,
                    "homeTp": false,
                    "channelIdx": 104,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 1,
                    "factoryDefault": true,
                    "polarization": "Horizontal",
                    "ONID": 0,
                    "codeRate": 9,
                    "satelliteId": "0192",
                    "symbolRate": 27500,
                    "frequency": 12304,
                    "homeTp": false,
                    "channelIdx": 105,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 1,
                    "factoryDefault": true,
                    "polarization": "Vertical",
                    "ONID": 0,
                    "codeRate": 2,
                    "satelliteId": "0192",
                    "symbolRate": 29700,
                    "frequency": 12324,
                    "homeTp": false,
                    "channelIdx": 106,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 0,
                    "factoryDefault": true,
                    "polarization": "Vertical",
                    "ONID": 0,
                    "codeRate": 3,
                    "satelliteId": "0192",
                    "symbolRate": 27500,
                    "frequency": 12363,
                    "homeTp": false,
                    "channelIdx": 107,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 1,
                    "factoryDefault": true,
                    "polarization": "Horizontal",
                    "ONID": 0,
                    "codeRate": 3,
                    "satelliteId": "0192",
                    "symbolRate": 27500,
                    "frequency": 12382,
                    "homeTp": false,
                    "channelIdx": 108,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 1,
                    "factoryDefault": true,
                    "polarization": "Vertical",
                    "ONID": 0,
                    "codeRate": 2,
                    "satelliteId": "0192",
                    "symbolRate": 29700,
                    "frequency": 12441,
                    "homeTp": false,
                    "channelIdx": 109,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 1,
                    "factoryDefault": true,
                    "polarization": "Horizontal",
                    "ONID": 0,
                    "codeRate": 2,
                    "satelliteId": "0192",
                    "symbolRate": 22000,
                    "frequency": 12522,
                    "homeTp": false,
                    "channelIdx": 110,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 0,
                    "factoryDefault": true,
                    "polarization": "Horizontal",
                    "ONID": 0,
                    "codeRate": 4,
                    "satelliteId": "0192",
                    "symbolRate": 22000,
                    "frequency": 12545,
                    "homeTp": false,
                    "channelIdx": 111,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 1,
                    "factoryDefault": true,
                    "polarization": "Horizontal",
                    "ONID": 0,
                    "codeRate": 2,
                    "satelliteId": "0192",
                    "symbolRate": 22000,
                    "frequency": 12574,
                    "homeTp": false,
                    "channelIdx": 112,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 1,
                    "factoryDefault": true,
                    "polarization": "Vertical",
                    "ONID": 0,
                    "codeRate": 2,
                    "satelliteId": "0192",
                    "symbolRate": 22000,
                    "frequency": 12581,
                    "homeTp": false,
                    "channelIdx": 113,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 1,
                    "factoryDefault": true,
                    "polarization": "Vertical",
                    "ONID": 0,
                    "codeRate": 2,
                    "satelliteId": "0192",
                    "symbolRate": 22000,
                    "frequency": 12610,
                    "homeTp": false,
                    "channelIdx": 114,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 0,
                    "factoryDefault": true,
                    "polarization": "Horizontal",
                    "ONID": 0,
                    "codeRate": 4,
                    "satelliteId": "0192",
                    "symbolRate": 22000,
                    "frequency": 12633,
                    "homeTp": false,
                    "channelIdx": 115,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 1,
                    "factoryDefault": true,
                    "polarization": "Vertical",
                    "ONID": 0,
                    "codeRate": 4,
                    "satelliteId": "0192",
                    "symbolRate": 22000,
                    "frequency": 12640,
                    "homeTp": false,
                    "channelIdx": 116,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 1,
                    "factoryDefault": true,
                    "polarization": "Vertical",
                    "ONID": 0,
                    "codeRate": 2,
                    "satelliteId": "0192",
                    "symbolRate": 22000,
                    "frequency": 12699,
                    "homeTp": false,
                    "channelIdx": 117,
                    "TSID": 0
                },
                {
                    "networkId": 0,
                    "nitVersion": 0,
                    "dvbsS2": 1,
                    "factoryDefault": true,
                    "polarization": "Vertical",
                    "ONID": 0,
                    "codeRate": 2,
                    "satelliteId": "0192",
                    "symbolRate": 22000,
                    "frequency": 12728,
                    "homeTp": false,
                    "channelIdx": 118,
                    "TSID": 0
                }
            ],
            "satelliteName": "ASTRA 19.2E",
            "factoryDefault": true,
            "deleteFlag": false,
            "satelliteId": "0192",
            "satLocation": "19.2E"
        },
        {
            "tpListLoad": false,
            "angleToGo": "65.65E",
            "TransponderList": [],
            "satelliteName": "ASTRA 23.5E",
            "factoryDefault": true,
            "deleteFlag": false,
            "satelliteId": "0235",
            "satLocation": "23.5E"
        },
        {
            "tpListLoad": false,
            "angleToGo": "65.65E",
            "TransponderList": [],
            "satelliteName": "BADR 26.0E",
            "factoryDefault": true,
            "deleteFlag": false,
            "satelliteId": "0260",
            "satLocation": "26.0E"
        },
        {
            "tpListLoad": false,
            "angleToGo": "65.65E",
            "TransponderList": [],
            "satelliteName": "ASTRA 28.2E",
            "factoryDefault": true,
            "deleteFlag": false,
            "satelliteId": "0282",
            "satLocation": "28.2E"
        },
        {
            "tpListLoad": false,
            "angleToGo": "65.65E",
            "TransponderList": [],
            "satelliteName": "HISPASAT 1 D/E 30.0W",
            "factoryDefault": true,
            "deleteFlag": false,
            "satelliteId": "3300",
            "satLocation": "30.0W"
        },
        {
            "tpListLoad": false,
            "angleToGo": "65.65E",
            "TransponderList": [],
            "satelliteName": "EUTELSAT 33B 33.0E",
            "factoryDefault": true,
            "deleteFlag": false,
            "satelliteId": "0330",
            "satLocation": "33.0E"
        },
        {
            "tpListLoad": false,
            "angleToGo": "65.65E",
            "TransponderList": [],
            "satelliteName": "EUTELSAT 36 B/C 36.0E",
            "factoryDefault": true,
            "deleteFlag": false,
            "satelliteId": "0360",
            "satLocation": "36.0E"
        },
        {
            "tpListLoad": false,
            "angleToGo": "65.65E",
            "TransponderList": [],
            "satelliteName": "HELLAS SAT2 39.0E",
            "factoryDefault": true,
            "deleteFlag": false,
            "satelliteId": "0390",
            "satLocation": "39.0E"
        },
        {
            "tpListLoad": false,
            "angleToGo": "65.65E",
            "TransponderList": [],
            "satelliteName": "EXPRESS AM7 40.0E",
            "factoryDefault": true,
            "deleteFlag": false,
            "satelliteId": "0400",
            "satLocation": "40.0E"
        },
        {
            "tpListLoad": false,
            "angleToGo": "65.65E",
            "TransponderList": [],
            "satelliteName": "TURKSAT 42.0E",
            "factoryDefault": true,
            "deleteFlag": false,
            "satelliteId": "0420",
            "satLocation": "42.0E"
        },
        {
            "tpListLoad": false,
            "angleToGo": "65.65E",
            "TransponderList": [],
            "satelliteName": "INTELSAT20 68.5E",
            "factoryDefault": true,
            "deleteFlag": false,
            "satelliteId": "0685",
            "satLocation": "68.5E"
        },
        {
            "tpListLoad": false,
            "angleToGo": "65.65E",
            "TransponderList": [],
            "satelliteName": "ASIASAT3S 105.5E",
            "factoryDefault": true,
            "deleteFlag": false,
            "satelliteId": "1055",
            "satLocation": "105.5E"
        },
        {
            "tpListLoad": false,
            "angleToGo": "65.65E",
            "TransponderList": [],
            "satelliteName": "YAMAL 300K 90.0E",
            "factoryDefault": true,
            "deleteFlag": false,
            "satelliteId": "0900",
            "satLocation": "90.0E"
        },
        {
            "tpListLoad": false,
            "angleToGo": "65.65E",
            "TransponderList": [],
            "satelliteName": "ABS2A 75.0E",
            "factoryDefault": true,
            "deleteFlag": false,
            "satelliteId": "0750",
            "satLocation": "75.0E"
        },
        {
            "tpListLoad": false,
            "angleToGo": "65.65E",
            "TransponderList": [],
            "satelliteName": "EXPRESS AM5/AT2 140.0E",
            "factoryDefault": true,
            "deleteFlag": false,
            "satelliteId": "1400",
            "satLocation": "140.0E"
        },
        {
            "tpListLoad": false,
            "angleToGo": "65.65E",
            "TransponderList": [],
            "satelliteName": "INTELSAT15 85.2E",
            "factoryDefault": true,
            "deleteFlag": false,
            "satelliteId": "0852",
            "satLocation": "85.2E"
        },
        {
            "tpListLoad": false,
            "angleToGo": "65.65E",
            "TransponderList": [],
            "satelliteName": "EXPRESS AM33 96.5E",
            "factoryDefault": true,
            "deleteFlag": false,
            "satelliteId": "0965",
            "satLocation": "96.5E"
        },
        {
            "tpListLoad": false,
            "angleToGo": "65.65E",
            "TransponderList": [],
            "satelliteName": "INTELSAT904 60.0E",
            "factoryDefault": true,
            "deleteFlag": false,
            "satelliteId": "0600",
            "satLocation": "60.0E"
        },
        {
            "tpListLoad": false,
            "angleToGo": "65.65E",
            "TransponderList": [],
            "satelliteName": "EXPRESS AT1 56.0E",
            "factoryDefault": true,
            "deleteFlag": false,
            "satelliteId": "0560",
            "satLocation": "56.0E"
        },
        {
            "tpListLoad": false,
            "angleToGo": "65.65E",
            "TransponderList": [],
            "satelliteName": "EUTELSAT 7A/8C 7.3W",
            "factoryDefault": true,
            "deleteFlag": false,
            "satelliteId": "3527",
            "satLocation": "7.3W"
        },
        {
            "tpListLoad": false,
            "angleToGo": "65.65E",
            "TransponderList": [],
            "satelliteName": "INTELSAT903 34.5W",
            "factoryDefault": true,
            "deleteFlag": false,
            "satelliteId": "3255",
            "satLocation": "34.5W"
        },
        {
            "tpListLoad": false,
            "angleToGo": "65.65E",
            "TransponderList": [],
            "satelliteName": "INTELSAT1R 50.0W",
            "factoryDefault": true,
            "deleteFlag": false,
            "satelliteId": "3100",
            "satLocation": "50.0W"
        },
        {
            "tpListLoad": false,
            "angleToGo": "65.65E",
            "TransponderList": [],
            "satelliteName": "NSS12 57.0E",
            "factoryDefault": true,
            "deleteFlag": false,
            "satelliteId": "0570",
            "satLocation": "57.0E"
        },
        {
            "tpListLoad": false,
            "angleToGo": "65.65E",
            "TransponderList": [],
            "satelliteName": "OPTUS D1 160.0E",
            "factoryDefault": true,
            "deleteFlag": false,
            "satelliteId": "1600",
            "satLocation": "160.0E"
        },
        {
            "tpListLoad": false,
            "angleToGo": "65.65E",
            "TransponderList": [],
            "satelliteName": "NSS 6 95.0E",
            "factoryDefault": true,
            "deleteFlag": false,
            "satelliteId": "0950",
            "satLocation": "95.0E"
        },
        {
            "tpListLoad": false,
            "angleToGo": "65.65E",
            "TransponderList": [],
            "satelliteName": "VINASAT2 131.8E",
            "factoryDefault": true,
            "deleteFlag": false,
            "satelliteId": "1318",
            "satLocation": "131.8E"
        },
        {
            "tpListLoad": false,
            "angleToGo": "65.65E",
            "TransponderList": [],
            "satelliteName": "ARABSAT 5A 30.5E",
            "factoryDefault": true,
            "deleteFlag": false,
            "satelliteId": "0305",
            "satLocation": "30.5E"
        },
        {
            "tpListLoad": false,
            "angleToGo": "65.65E",
            "TransponderList": [],
            "satelliteName": "ARABSAT 5C 20.0E",
            "factoryDefault": true,
            "deleteFlag": false,
            "satelliteId": "0200",
            "satLocation": "20.0E"
        },
        {
            "tpListLoad": false,
            "angleToGo": "65.65E",
            "TransponderList": [],
            "satelliteName": "EUTELSAT 21B 21.5E",
            "factoryDefault": true,
            "deleteFlag": false,
            "satelliteId": "0215",
            "satLocation": "21.5E"
        },
        {
            "tpListLoad": false,
            "angleToGo": "65.65E",
            "TransponderList": [],
            "satelliteName": "INTELSAT22 72.1E",
            "factoryDefault": true,
            "deleteFlag": false,
            "satelliteId": "0721",
            "satLocation": "72.1E"
        },
        {
            "tpListLoad": false,
            "angleToGo": "65.65E",
            "TransponderList": [],
            "satelliteName": "ASTRA 5B 31.5E",
            "factoryDefault": true,
            "deleteFlag": false,
            "satelliteId": "0315",
            "satLocation": "31.5E"
        },
        {
            "tpListLoad": false,
            "angleToGo": "65.65E",
            "TransponderList": [],
            "satelliteName": "KAZSAT 3 58.5E",
            "factoryDefault": true,
            "deleteFlag": false,
            "satelliteId": "0585",
            "satLocation": "58.5E"
        },
        {
            "tpListLoad": false,
            "angleToGo": "65.65E",
            "TransponderList": [],
            "satelliteName": "OTHERS1",
            "factoryDefault": true,
            "deleteFlag": false,
            "satelliteId": "0001",
            "satLocation": "0.1E"
        },
        {
            "tpListLoad": false,
            "angleToGo": "65.65E",
            "TransponderList": [],
            "satelliteName": "OTHERS2",
            "factoryDefault": true,
            "deleteFlag": false,
            "satelliteId": "0002",
            "satLocation": "0.2E"
        },
        {
            "tpListLoad": false,
            "angleToGo": "65.65E",
            "TransponderList": [],
            "satelliteName": "OTHERS3",
            "factoryDefault": true,
            "deleteFlag": false,
            "satelliteId": "0003",
            "satLocation": "0.3E"
        },
        {
            "tpListLoad": false,
            "angleToGo": "65.65E",
            "TransponderList": [],
            "satelliteName": "OTHERS4",
            "factoryDefault": true,
            "deleteFlag": false,
            "satelliteId": "0004",
            "satLocation": "0.4E"
        }
    ],
    "operatorConfigObj":{
       "operatorType":"OPERATOR_TYPE_NONE",
       "scanType":0,
       "mduGenType":""
    },
    "motorPositionObj":{
       "lastPosition":0
    },
    "homeTpList":[]
 };

export { dummyOldTll, dummyNewTll, dummyNewJson };

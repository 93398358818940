import FileSaver from "file-saver";
import ExportNewTll from "./ExportNewTll";
import {registeredNewModelName, registeredOldModelName, registeredNonWebOSModelName} from "../utils/Common";
import {dummyOldTll} from "../utils/Dummy";
import {VchName2HexVchName} from "./ChannelNameParcer"

// Append a child element
const appendChildElementWithValue2Parent = (xmlDoc, parent, childName, childValue) => {
  const child = xmlDoc.createElement(childName);
  const childValueNode = xmlDoc.createTextNode(childValue);
  parent.appendChild(child);
  child.appendChild(childValueNode);
};

const ExportOldTll = (xmlDocString, tvConfig, channelInfoList) => {
  console.log('Old webOS model TLL export')
  // documentElement always represents the root node
  if (xmlDocString === "") {
    xmlDocString = dummyOldTll;
  } 
  const xmlDoc = new DOMParser().parseFromString(xmlDocString, "text/xml");

  xmlDoc.getElementsByTagName("ModelName")[0].childNodes[0].nodeValue = tvConfig.modelName; // + "-" + tvInfo.modelName.suffix;
  
  if (registeredNonWebOSModelName.findIndex(model => model.value === tvConfig.modelName.replace(/^[0-9][0-9]/g, '')) === -1) { //if TV is not from non-webOS model
    xmlDoc.getElementsByTagName("BroadcastCountrySetting")[0].childNodes[0].nodeValue = tvConfig.country;
    /* Hotel mode */
    xmlDoc.getElementsByTagName("enableHotelMode")[0].childNodes[0].nodeValue = tvConfig.hotelMode; // off : on
    xmlDoc.getElementsByTagName("powerOnStatus")[0].childNodes[0].nodeValue = tvConfig.powerOnStatus; // stand_by : lst : power_on
    xmlDoc.getElementsByTagName("enablePowerOnDefaultVolume")[0].childNodes[0].nodeValue = tvConfig.enablePowerOnDefaultVolume; // off : on
    xmlDoc.getElementsByTagName("enableStartVolume")[0].childNodes[0].nodeValue = tvConfig.enableStartVolume; // off : on
    xmlDoc.getElementsByTagName("powerOnDefaultVolume")[0].childNodes[0].nodeValue = tvConfig.powerOnDefaultVolume; // 0 - 100
    xmlDoc.getElementsByTagName("minimumVolume")[0].childNodes[0].nodeValue = tvConfig.minimumVolume; // 0 : 0 - 100
    xmlDoc.getElementsByTagName("maximumVolume")[0].childNodes[0].nodeValue = tvConfig.maximumVolume; // 0: 0 - 100

    /* Key Management */
    xmlDoc.getElementsByTagName("keyManagement")[0].childNodes[0].nodeValue = tvConfig.keyManagement; // off : on
    xmlDoc.getElementsByTagName("enableIrRemote")[0].childNodes[0].nodeValue = tvConfig.enableIrRemote; // normal : usePwrOnly : blockAll
    xmlDoc.getElementsByTagName("enableLocalKey")[0].childNodes[0].nodeValue = tvConfig.enableLocalKey; // normal : usePwrOnly : blockAll

    /* Limited mode */
    xmlDoc.getElementsByTagName("enableLimitedMode")[0].childNodes[0].nodeValue = tvConfig.enableLimitedMode; // off : on  
    xmlDoc.getElementsByTagName("enterStationMenu")[0].childNodes[0].nodeValue = tvConfig.enterStationMenu; // off : on  
    xmlDoc.getElementsByTagName("enableProgramChange")[0].childNodes[0].nodeValue = tvConfig.enableProgramChange; // off : on  
    xmlDoc.getElementsByTagName("enableMenuKey")[0].childNodes[0].nodeValue = tvConfig.enableMenuKey; // off : on  
    xmlDoc.getElementsByTagName("enableOsdDisplay")[0].childNodes[0].nodeValue = tvConfig.enableOsdDisplay; // off : on  
    xmlDoc.getElementsByTagName("enableSystemProviderMode")[0].childNodes[0].nodeValue = tvConfig.enableSystemProviderMode; // off : on  
    
    /* Commercial Settings */
    xmlDoc.getElementsByTagName("instantOn")[0].childNodes[0].nodeValue = tvConfig.instantOn; // enable: disable
    xmlDoc.getElementsByTagName("acOn")[0].childNodes[0].nodeValue = tvConfig.acOn; // 0 (off) : 2 (reboot) : 3 (mute)
  }
 
  const chNode = xmlDoc.getElementsByTagName("CHANNEL")[0];
  while (chNode.firstChild) {
    chNode.removeChild(chNode.lastChild);
  }
  const dtv = xmlDoc.createElement("DTV");
  chNode.appendChild(dtv);
  const iptv = xmlDoc.createElement("IPTV");
  chNode.appendChild(iptv);

  for (let i = 0; i < channelInfoList.length; i++) {
    const chType = channelInfoList[i].type;
    if ((chType === "RF-Terrestrial") | (chType === "RF-Cable") | (chType === "Satellite")) {
      const item = xmlDoc.createElement("ITEM");
      dtv.appendChild(item);
      appendChildElementWithValue2Parent(xmlDoc, item, "prNum", channelInfoList[i].number);
      appendChildElementWithValue2Parent(xmlDoc, item, "minorNum", channelInfoList[i].minorNum || channelInfoList[i].minorNumber);
      appendChildElementWithValue2Parent(xmlDoc, item, "original_network_id", channelInfoList[i].original_network_id || channelInfoList[i].ONID);
      appendChildElementWithValue2Parent(xmlDoc, item, "transport_id", channelInfoList[i].transport_id || channelInfoList[i].TSID);
      appendChildElementWithValue2Parent(xmlDoc, item, "network_id", channelInfoList[i].network_id || channelInfoList[i].networkId);
      appendChildElementWithValue2Parent(xmlDoc, item, "service_id", channelInfoList[i].service_id || channelInfoList[i].SVCID);
      appendChildElementWithValue2Parent(xmlDoc, item, "physicalNum", channelInfoList[i].physicalNum || channelInfoList[i].physicalNumber);
      appendChildElementWithValue2Parent(xmlDoc, item, "sourceIndex", channelInfoList[i].sourceIndex || 1);
      appendChildElementWithValue2Parent(xmlDoc, item, "serviceType", channelInfoList[i].serviceType);
      appendChildElementWithValue2Parent(xmlDoc, item, "special_data", channelInfoList[i].special_data || channelInfoList[i].specialData);
      appendChildElementWithValue2Parent(
        xmlDoc,
        item,
        "frequency",
        chType === "Satellite" ? Number(channelInfoList[i].frequency) : Number(channelInfoList[i].frequency) * 1000
      );
      appendChildElementWithValue2Parent(xmlDoc, item, "nitVersion", channelInfoList[i].nitVersion);
      appendChildElementWithValue2Parent(xmlDoc, item, "mapType", channelInfoList[i].mapType || 1);
      appendChildElementWithValue2Parent(xmlDoc, item, "mapAttr", channelInfoList[i].mapAttr || 0);
      //appendChildElementWithValue2Parent(xmlDoc, item, "programNo", channelInfoList[i].programNo);
      if (typeof channelInfoList[i].programNo !== "undefined" || typeof channelInfoList[i].programNum !== "undefined")
        appendChildElementWithValue2Parent(xmlDoc, item, "programNo", channelInfoList[i].programNo || channelInfoList[i].programNum);
      if (typeof channelInfoList[i].favoriteIdxH !== "undefined") {
        appendChildElementWithValue2Parent(xmlDoc, item, "favoriteIdxA", channelInfoList[i].favoriteIdxA);
        appendChildElementWithValue2Parent(xmlDoc, item, "favoriteIdxB", channelInfoList[i].favoriteIdxB);
        appendChildElementWithValue2Parent(xmlDoc, item, "favoriteIdxC", channelInfoList[i].favoriteIdxC);
        appendChildElementWithValue2Parent(xmlDoc, item, "favoriteIdxD", channelInfoList[i].favoriteIdxD);
        appendChildElementWithValue2Parent(xmlDoc, item, "favoriteIdxE", channelInfoList[i].favoriteIdxE);
        appendChildElementWithValue2Parent(xmlDoc, item, "favoriteIdxF", channelInfoList[i].favoriteIdxF);
        appendChildElementWithValue2Parent(xmlDoc, item, "favoriteIdxG", channelInfoList[i].favoriteIdxG);
        appendChildElementWithValue2Parent(xmlDoc, item, "favoriteIdxH", channelInfoList[i].favoriteIdxH);
      }
      appendChildElementWithValue2Parent(xmlDoc, item, "isInvisable", channelInfoList[i].isInvisable || 0);
      appendChildElementWithValue2Parent(xmlDoc, item, "isBlocked", channelInfoList[i].isBlocked || 0);
      appendChildElementWithValue2Parent(xmlDoc, item, "isSkipped", channelInfoList[i].isSkipped || 0);
      appendChildElementWithValue2Parent(xmlDoc, item, "isNumUnSel", channelInfoList[i].isNumUnSel || 0);
      appendChildElementWithValue2Parent(xmlDoc, item, "isDeleted", channelInfoList[i].isDeleted || 0);
      appendChildElementWithValue2Parent(xmlDoc, item, "chNameByte", channelInfoList[i].chNameByte || 0);
      appendChildElementWithValue2Parent(xmlDoc, item, "isDisabled", channelInfoList[i].isDisabled || 0);
      //appendChildElementWithValue2Parent(xmlDoc, item, "isScrambled", channelInfoList[i].isScrambled);
      if (typeof channelInfoList[i].isScrambled !== "undefined") appendChildElementWithValue2Parent(xmlDoc, item, "isScrambled", channelInfoList[i].isScrambled || 0);
      if (typeof channelInfoList[i].hexVchName !== "undefined") {
        appendChildElementWithValue2Parent(xmlDoc, item, "hexVchName", VchName2HexVchName(channelInfoList[i].name));
        appendChildElementWithValue2Parent(xmlDoc, item, "notConvertedLengthOfVchName", channelInfoList[i].notConvertedLengthOfVchName);
      }
      appendChildElementWithValue2Parent(xmlDoc, item, "vchName", channelInfoList[i].name);
      // appendChildElementWithValue2Parent(xmlDoc, item, "lengthOfVchName", channelInfoList[i].lengthOfVchName || channelInfoList[i].name.length);
      appendChildElementWithValue2Parent(xmlDoc, item, "lengthOfVchName", channelInfoList[i].name.length);
      appendChildElementWithValue2Parent(xmlDoc, item, "hSettingIDHandle", channelInfoList[i].hSettingIDHandle || 0);
      appendChildElementWithValue2Parent(xmlDoc, item, "usSatelliteHandle", channelInfoList[i].usSatelliteHandle || 0);
      appendChildElementWithValue2Parent(xmlDoc, item, "isUserSelCHNo", channelInfoList[i].isUserSelCHNo || 1);
      if (typeof channelInfoList[i].logoIndex !== "undefined") appendChildElementWithValue2Parent(xmlDoc, item, "logoIndex", channelInfoList[i].logoIndex);
      if (typeof channelInfoList[i].videoStreamType !== "undefined") appendChildElementWithValue2Parent(xmlDoc, item, "videoStreamType", channelInfoList[i].videoStreamType);
      if (channelInfoList[i].encrypt) appendChildElementWithValue2Parent(xmlDoc, item, "forensic_tunning", channelInfoList[i].encrypt === "Scramble" ? 1 : 0);
    } else if (iptv !== null && (chType === "IP-UDP") | (chType === "IP-RTP")) {
      const item = xmlDoc.createElement("ITEM");
      iptv.appendChild(item);
      appendChildElementWithValue2Parent(xmlDoc, item, "source_address", "0.0.0.0");
      appendChildElementWithValue2Parent(xmlDoc, item, "multicast_address", channelInfoList[i].ipAddr);
      appendChildElementWithValue2Parent(xmlDoc, item, "port", channelInfoList[i].ipPort);
      appendChildElementWithValue2Parent(xmlDoc, item, "protocol", chType === "IP-UDP" ? 0 : 1);
      appendChildElementWithValue2Parent(xmlDoc, item, "logicalNum", channelInfoList[i].number);
      appendChildElementWithValue2Parent(xmlDoc, item, "serviceId", Number(channelInfoList[i].service_id) || 0);
      appendChildElementWithValue2Parent(xmlDoc, item, "hexVchName", VchName2HexVchName(channelInfoList[i].name));
      appendChildElementWithValue2Parent(xmlDoc, item, "fixed_vchName", channelInfoList[i].fixed_vchName || 1);
      appendChildElementWithValue2Parent(xmlDoc, item, "vchName", channelInfoList[i].name);
      appendChildElementWithValue2Parent(xmlDoc, item, "hidden", 0);
      if (channelInfoList[i].encrypt) appendChildElementWithValue2Parent(xmlDoc, item, "forensic_tunning", channelInfoList[i].encrypt ? 1 : 0);
    }
  }

  const xmlSerializer = new XMLSerializer();
  const xmlOutput = xmlSerializer.serializeToString(xmlDoc);
  // console.log("xmlOutput:", xmlOutput);
  let filename = 'converted.tll';
  if (registeredNonWebOSModelName.findIndex(model => model.value === tvConfig.modelName.replace(/^[0-9][0-9]/g, '')) !== -1)
    filename = `GlobalClone_conv.tll`;
  else 
    filename = (tvConfig.modelName.slice(0, 3) === 'STB')? `${tvConfig.modelName}_conv.tll` : `xx${tvConfig.modelName.replace(/^[0-9][0-9]/g, '')}_conv.tll`
  const file = new File([xmlOutput], filename, {
    type: "text/xml;charset=utf-8",
  });
  FileSaver.saveAs(file);
};

const ExportNonWebOSTll = (xmlDocString, tvConfig, channelInfoList) => {
  // documentElement always represents the root node
  console.log('Non webOS model TLL export');
  if (xmlDocString === "") {
    xmlDocString = dummyOldTll;
  } 
  const xmlDoc = new DOMParser().parseFromString(xmlDocString, "text/xml");

  xmlDoc.getElementsByTagName("ModelName")[0].childNodes[0].nodeValue = tvConfig.modelName; // + "-" + tvInfo.modelName.suffix;
  
  const dtv = xmlDoc.getElementsByTagName("DTV")[0];
  while (dtv.firstChild) {
    dtv.removeChild(dtv.firstChild);
  }
  //const dtv = xmlDoc.createElement("DTV");
  //chNode.appendChild(dtv);

  for (let i = 0; i < channelInfoList.length; i++) {
    const chType = channelInfoList[i].type;
    const item = xmlDoc.createElement("ITEM");
    dtv.appendChild(item);

    appendChildElementWithValue2Parent(xmlDoc, item, "prNum", (channelInfoList[i].tvRadio === "TV")? channelInfoList[i].number : channelInfoList[i].number | 0x4000);
    appendChildElementWithValue2Parent(xmlDoc, item, "minorNum", channelInfoList[i].minorNum || channelInfoList[i].minorNumber);
    appendChildElementWithValue2Parent(xmlDoc, item, "original_network_id", channelInfoList[i].original_network_id || channelInfoList[i].ONID);
    appendChildElementWithValue2Parent(xmlDoc, item, "transport_id", channelInfoList[i].transport_id || channelInfoList[i].TSID);
    appendChildElementWithValue2Parent(xmlDoc, item, "network_id", channelInfoList[i].network_id || channelInfoList[i].networkId);
    appendChildElementWithValue2Parent(xmlDoc, item, "service_id", channelInfoList[i].service_id || channelInfoList[i].SVCID);
    appendChildElementWithValue2Parent(xmlDoc, item, "physicalNum", channelInfoList[i].physicalNum || channelInfoList[i].physicalNumber);
    appendChildElementWithValue2Parent(xmlDoc, item, "sourceIndex", channelInfoList[i].sourceIndex || 1);
    appendChildElementWithValue2Parent(xmlDoc, item, "serviceType", channelInfoList[i].serviceType);
    appendChildElementWithValue2Parent(xmlDoc, item, "special_data", channelInfoList[i].special_data || channelInfoList[i].specialData);
    appendChildElementWithValue2Parent(
      xmlDoc,
      item,
      "frequency",
      chType === "Satellite" ? Number(channelInfoList[i].frequency) : Number(channelInfoList[i].frequency) * 1000
    );
    appendChildElementWithValue2Parent(xmlDoc, item, "nitVersion", channelInfoList[i].nitVersion);
    appendChildElementWithValue2Parent(xmlDoc, item, "mapType", channelInfoList[i].mapType || 1);
    appendChildElementWithValue2Parent(xmlDoc, item, "mapAttr", channelInfoList[i].mapAttr || 0);
    //appendChildElementWithValue2Parent(xmlDoc, item, "programNo", channelInfoList[i].programNo);
    if (typeof channelInfoList[i].programNo !== "undefined" || typeof channelInfoList[i].programNum !== "undefined")
      appendChildElementWithValue2Parent(xmlDoc, item, "programNo", channelInfoList[i].programNo || channelInfoList[i].programNum);
    if (typeof channelInfoList[i].favoriteIdxH !== "undefined") {
      appendChildElementWithValue2Parent(xmlDoc, item, "favoriteIdxA", channelInfoList[i].favoriteIdxA);
      appendChildElementWithValue2Parent(xmlDoc, item, "favoriteIdxB", channelInfoList[i].favoriteIdxB);
      appendChildElementWithValue2Parent(xmlDoc, item, "favoriteIdxC", channelInfoList[i].favoriteIdxC);
      appendChildElementWithValue2Parent(xmlDoc, item, "favoriteIdxD", channelInfoList[i].favoriteIdxD);
      appendChildElementWithValue2Parent(xmlDoc, item, "favoriteIdxE", channelInfoList[i].favoriteIdxE);
      appendChildElementWithValue2Parent(xmlDoc, item, "favoriteIdxF", channelInfoList[i].favoriteIdxF);
      appendChildElementWithValue2Parent(xmlDoc, item, "favoriteIdxG", channelInfoList[i].favoriteIdxG);
      appendChildElementWithValue2Parent(xmlDoc, item, "favoriteIdxH", channelInfoList[i].favoriteIdxH);
    }
    appendChildElementWithValue2Parent(xmlDoc, item, "isInvisable", channelInfoList[i].isInvisable || 0);
    appendChildElementWithValue2Parent(xmlDoc, item, "isBlocked", channelInfoList[i].isBlocked || 0);
    appendChildElementWithValue2Parent(xmlDoc, item, "isSkipped", channelInfoList[i].isSkipped || 0);
    appendChildElementWithValue2Parent(xmlDoc, item, "isNumUnSel", channelInfoList[i].isNumUnSel || 0);
    appendChildElementWithValue2Parent(xmlDoc, item, "isDeleted", channelInfoList[i].isDeleted || 0);
    appendChildElementWithValue2Parent(xmlDoc, item, "chNameByte", channelInfoList[i].chNameByte || 0);
    appendChildElementWithValue2Parent(xmlDoc, item, "isDisabled", channelInfoList[i].isDisabled || 0);
    //appendChildElementWithValue2Parent(xmlDoc, item, "isScrambled", channelInfoList[i].isScrambled);
    if (typeof channelInfoList[i].isScrambled !== "undefined") appendChildElementWithValue2Parent(xmlDoc, item, "isScrambled", channelInfoList[i].isScrambled || 0);
    if (typeof channelInfoList[i].hexVchName !== "undefined") {
      appendChildElementWithValue2Parent(xmlDoc, item, "hexVchName", VchName2HexVchName(channelInfoList[i].name));
      appendChildElementWithValue2Parent(xmlDoc, item, "notConvertedLengthOfVchName", channelInfoList[i].notConvertedLengthOfVchName);
    }
    appendChildElementWithValue2Parent(xmlDoc, item, "vchName", channelInfoList[i].name);
    // appendChildElementWithValue2Parent(xmlDoc, item, "lengthOfVchName", channelInfoList[i].lengthOfVchName || channelInfoList[i].name.length);
    appendChildElementWithValue2Parent(xmlDoc, item, "lengthOfVchName", channelInfoList[i].name.length);
    appendChildElementWithValue2Parent(xmlDoc, item, "hSettingIDHandle", channelInfoList[i].hSettingIDHandle || 0);
    appendChildElementWithValue2Parent(xmlDoc, item, "usSatelliteHandle", channelInfoList[i].usSatelliteHandle || 0);
    appendChildElementWithValue2Parent(xmlDoc, item, "isUserSelCHNo", channelInfoList[i].isUserSelCHNo || 1);
    if (typeof channelInfoList[i].logoIndex !== "undefined") appendChildElementWithValue2Parent(xmlDoc, item, "logoIndex", channelInfoList[i].logoIndex);
    if (typeof channelInfoList[i].videoStreamType !== "undefined") appendChildElementWithValue2Parent(xmlDoc, item, "videoStreamType", channelInfoList[i].videoStreamType);
  }

  const xmlSerializer = new XMLSerializer();
  const xmlOutput = xmlSerializer.serializeToString(xmlDoc);
  // console.log("xmlOutput:", xmlOutput);
  const file = new File([xmlOutput], `GlobalClone_conv.tll`, {
    type: "text/xml;charset=utf-8",
  });
  FileSaver.saveAs(file);
};

const ExportTll = (xmlDoc, tvConfig, channelInfoList) => {
  const checkModelName = tvConfig.modelName;//.replace(/^[0-9][0-9]/g, '');
  console.log(checkModelName);
  //if (!xmlDoc.getElementsByTagName("legacybroadcast") || xmlDoc.getElementsByTagName("legacybroadcast").length === 0) ExportOldTll(xmlDoc, modelName, channelInfoList);
  if (registeredNewModelName.findIndex(model => model.value === checkModelName) !== -1) {
    ExportNewTll(xmlDoc, tvConfig, channelInfoList);
  } else if (registeredOldModelName.findIndex(model => model.value === checkModelName) !== -1) {
    ExportOldTll(xmlDoc, tvConfig, channelInfoList);
  } else if (registeredNonWebOSModelName.findIndex(model => model.value === checkModelName) !== -1) {
    ExportNonWebOSTll(xmlDoc, tvConfig, channelInfoList);
  } else alert("Error in export TLL: Modelname is not registered!");
};

export default ExportTll;

import React from "react";
import Select from 'react-select';
import { countryOptions, powerOnStatusOptions, enableLocalKeyOptions, instantOnOptions, registeredNewModelName, registeredOldModelName, registeredNonWebOSModelName } from "../utils/Common";

import "./TVConfig.css";

const customStyles = {
  control: (styles, state) => ({
    ...styles,
    width: '200px',
    minHeight: 35,
    height: 35,
    padding: 0,
    margin: 0,
    borderRadius: '20px',
    border: state.isDisabled ? 'none':'2px solid gray',
    borderColor: state.isFocused ? "#C70851" : "gray",
    // Removes weird border around container
    boxShadow: state.isFocused ? '#C70851' : 'gray',
    "&:hover": {
      // Overwrittes the different states of border
      borderColor: state.isFocused ? "#C70851" : "#C70851",
      cursor: 'pointer',
    }
  }),
  valueContainer: (styles) => ({
    ...styles,
    padding: '0px 8px',
    height: '30px',
  }),
  indicatorsContainer: (styles) => ({
    ...styles,
    height: '30px',
  }),
  menu: (styles) => ({
    ...styles,
    marginTop: '2px',
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isSelected ? '#C70851' : '',
      cursor: isDisabled ? 'not-allowed' : 'default',
      ':hover': {
        ...styles[':hover'],
        backgroundColor: '#ffcccb',
        color: 'black',
      },
    };
  },
}

const TVConfig = ({ tvConfig, handleChangeConfig }) => {

  const wrapHandleClick = (event) => {
    //console.log(event.target.id);
    handleChangeConfig(event.target.id, tvConfig[event.target.id]==='on'? 'off':'on');
  }

  const wrapHandleChange = (event) => {
    handleChangeConfig(event.target.id, event.target.value);
  };
  const instantOnHandleChange = (event) => {
    if(event.value === 'off'){
      handleChangeConfig('instantOn', 'diable');
      handleChangeConfig('acOn', 0);
    } else if(event.value === 'Instant On Reboot'){
      handleChangeConfig('instantOn', 'enable');
      handleChangeConfig('acOn', 2);
    } else if(event.value === 'Instant On Mute') {
      handleChangeConfig('instantOn', 'enable');
      handleChangeConfig('acOn', 3);
    }
  };
  // let modelNameError = 'No error';
  const wrapSelectChange = (id) => (event) => {
    // console.log(id);
    // console.log(event);
    handleChangeConfig(id, event.value);
  }

  let hotelModeClassName = (tvConfig.hotelMode==='on') ? '' : "disabled";
  let volumeClassName = (tvConfig.hotelMode==='on' && tvConfig.enablePowerOnDefaultVolume==='on') ? '' : "disabled";
  let startVolumeClassName = (tvConfig.hotelMode==='on' && tvConfig.enablePowerOnDefaultVolume==='on' && tvConfig.enableStartVolume==='on')? '' : "disabled";
  let keyManagementClassName = (tvConfig.hotelMode==='on' && tvConfig.keyManagement==='on') ? null : "disabled";
  let limitedModeClassName = (tvConfig.hotelMode==='on' && tvConfig.enableLimitedMode==='on') ? null : "disabled";
  
  let instantOnValue = tvConfig.instantOn==='disable'? 'off': tvConfig.acOn===2 ? 'Instant On Reboot' : 'Instant On Mute';

  // console.log(JSON.stringify(tvConfig.hotelMode));
  // console.log(JSON.stringify(hotelModeToggle));
  const allTVModels = registeredNewModelName.concat(registeredOldModelName, registeredNonWebOSModelName);

  return (
    <div className="tllTVConfig">
      <div className="tllSectionTitle">Model Information</div>
      <div className="tllSection">
        <ul>
          <li className="tllModelInfoItem"> <div className="tillModelInfoItemName">Model Name</div> 
              <Select styles={customStyles} value={allTVModels.find(element => element.value === tvConfig.modelName)} onChange={wrapSelectChange('modelName')} options={allTVModels} />
          </li>
          <li className="tllModelInfoItem"><div className="tillModelInfoItemName">Country</div>
              <Select styles={customStyles} value={countryOptions.find(element => element.value === tvConfig.country)} onChange={wrapSelectChange('country')} options={countryOptions} />
          </li>
        </ul>
      </div>
      <div className="tllSectionTitle">Hotel Mode</div>
      <div className="tllSection">
        <ul>
          <li>Enable <button className='tllSectionButton' id="hotelMode" onClick={wrapHandleClick}>{tvConfig.hotelMode}</button>
          </li>
          <li className={hotelModeClassName}>Power On Status
              <Select isDisabled={hotelModeClassName==='disabled'?true:false} styles={customStyles} value={powerOnStatusOptions.find(element => element.value === tvConfig.powerOnStatus)} onChange={wrapSelectChange('powerOnStatus')} options={powerOnStatusOptions} />
          </li>
          <li className={hotelModeClassName}>Volume
            <button className='tllSectionButton' disabled={hotelModeClassName} id="enablePowerOnDefaultVolume" onClick={wrapHandleClick}>{tvConfig.enablePowerOnDefaultVolume}</button>
            <ul className="secondLevel">
              <li className={volumeClassName}>Start Volume
                <button className={volumeClassName + ' tllSectionButton'} disabled={volumeClassName} id="enableStartVolume" onClick={wrapHandleClick}>{tvConfig.enableStartVolume}</button>
                <input className={startVolumeClassName + ' tllSectionInput'} disabled={startVolumeClassName} type="text" value={tvConfig.powerOnDefaultVolume} onChange={wrapHandleChange} id="powerOnDefaultVolume"/>
              </li>
              <li className={volumeClassName}>Maximum Volume
                <input className={volumeClassName + ' tllSectionInput'} disabled={volumeClassName} type="text" value={tvConfig.maximumVolume} onChange={wrapHandleChange} id="maximumVolume"/>
              </li>
              <li className={volumeClassName}>Minimum Volume
                <input className={volumeClassName + ' tllSectionInput'} disabled={volumeClassName} type="text" value={tvConfig.minimumVolume} onChange={wrapHandleChange} id="minimumVolume"/>
              </li>
            </ul>
          </li>
          <li className={hotelModeClassName}>Key Management
            <button className='tllSectionButton' disabled={hotelModeClassName} id="keyManagement" onClick={wrapHandleClick}>{tvConfig.keyManagement}</button>
            <ul className="secondLevel">
              <li className={keyManagementClassName}>IR Operation
                <Select isDisabled={keyManagementClassName==='disabled'?true:false} styles={customStyles} value={enableLocalKeyOptions.find(element => element.value === tvConfig.enableIrRemote)} onChange={wrapSelectChange('enableIrRemote')} options={enableLocalKeyOptions} />
              </li>
              <li className={keyManagementClassName}>Local Key Operation
                <Select isDisabled={keyManagementClassName==='disabled'?true:false} styles={customStyles} value={enableLocalKeyOptions.find(element => element.value === tvConfig.enableLocalKey)} onChange={wrapSelectChange('enableLocalKey')} options={enableLocalKeyOptions} />
              </li>
            </ul>
          </li>
          <li className={hotelModeClassName}>Limited Mode
            <button className='tllSectionButton' disabled={hotelModeClassName} id="enableLimitedMode" onClick={wrapHandleClick}>{tvConfig.enableLimitedMode}</button>
            <ul className="secondLevel">
              <li className={limitedModeClassName}>Setup Menu<button className='tllSectionButton' disabled={limitedModeClassName} id="enterStationMenu" onClick={wrapHandleClick}>{tvConfig.enterStationMenu}</button></li>
              <li className={limitedModeClassName}>Programme Change<button className='tllSectionButton' disabled={limitedModeClassName} id="enableProgramChange" onClick={wrapHandleClick}>{tvConfig.enableProgramChange}</button></li>
              <li className={limitedModeClassName}>Menu Display<button className='tllSectionButton' disabled={limitedModeClassName} id="enableMenuKey" onClick={wrapHandleClick}>{tvConfig.enableMenuKey}</button></li>
              <li className={limitedModeClassName}>OSD Display<button className='tllSectionButton' disabled={limitedModeClassName} id="enableOsdDisplay" onClick={wrapHandleClick}>{tvConfig.enableOsdDisplay}</button></li>
              <li className={limitedModeClassName}>System Provider Mode<button className='tllSectionButton' disabled={limitedModeClassName} id="enableSystemProviderMode" onClick={wrapHandleClick}>{tvConfig.enableSystemProviderMode}</button></li>
            </ul>
          </li>
        </ul>
      </div>
      <div className="tllSectionTitle">General</div>
      <div className="tllSection">
        <ul>
          <li className={''}>Configuration
            <ul className="secondLevel">
              <li className={''}>Instant On
                <Select styles={customStyles} value={instantOnOptions.find(element => element.value === instantOnValue)} onChange={instantOnHandleChange} options={instantOnOptions} />
                {/* <select className={''} value={instantOnValue} onChange={instantOnHandleChange} id="instantOn">
                  {instantOnOptions.map(({ key, value, text }) => (<option key={key} value={value}>{text}</option>))}
                </select> */}
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  );
};

export const tvConfigDefault =  {
  modelName: "Unknown", 
  country: "EUU",

  hotelMode: "off", // off : on
  powerOnStatus: 'stand_by', // stand_by : lst : power_on
  enablePowerOnDefaultVolume: 'off', // off : on
  enableStartVolume: 'off', // off : on
  powerOnDefaultVolume: '0', // 0: 0 - 100
  maximumVolume: '100', // 0: 0 - 100
  minimumVolume: '0', // 0: 0 - 100
  
  keyManagement: 'off', // off : on
  enableIrRemote: 'normal', // normal : usePwrOnly : blockAll
  enableLocalKey: 'normal', // normal : usePwrOnly : blockAll

  enableLimitedMode: 'off', // off : on  
  enterStationMenu: 'off', // off : on  
  enableProgramChange: 'off', // off : on  
  enableMenuKey: 'off', // off : on  
  enableOsdDisplay: 'off', // off : on  
  enableSystemProviderMode: 'off', // off : on  
  instantOn: 'disable', // enable: disable
  acOn: '0', // 0 (off) : 2 (reboot) : 3 (mute)

};

export default TVConfig;

/*           
          Network Settings
                Wired Connection
                  Set Automatically
                  IP address
                  Subnet Mask
                  Gateway
                  DNS Server
                Wi-Fi Connection
                  Network
                  Password
          Pro:Centric
                Server type
                Mode
                WORF / Update Time
                Media type (RF/IP)
                (RF) Data Channel Type
                (RF) Data Channel Freq(kHz)
                (IP) Server address (IP Address/Domain Name)
                  (IP Address) IP Address
                  (Domain Name) Domain Name
                (IP) Port number
                Room Number
          General
                Configuration Setup
                  Select RCU
                  Number of RCU
                  Splash Offset Time
                  RS232 DC Power Outlet
                  One Channel Map
                  On Timer Operation
                  Radio Name Display
                  Teletext
                  15Min Auto Off
                  Instant On
                  Insert Image
                  No Signal Image
                  Healthcare Headphone Mode
                  Screen Saver
                  Default Sound Out
                  USB Power
                External Speaker
                Set ID Setup
                Power Saving
                HCEC Setup
                Clock Setup
                Password Change
                Lock Mode
                    Developer Mode */
import FileSaver from "file-saver";
//import React from "react";

// Append a child element
const pcdXmlAppendChild = (xmlDoc, parentElement, name, text) => {
  let childElement = xmlDoc.createElement(name);
  if (typeof text !== "undefined") {
    let textNode = xmlDoc.createTextNode(text);
    childElement.appendChild(textNode);
  }
  parentElement.appendChild(childElement);
  return childElement;
};

const PcdXmlGenerate = (channelInfoList, server) => {
  // documentElement always represents the root node
  const xmlDoc = new DOMParser().parseFromString('<?xml version="1.0" encoding="UTF-8"?>\n<Channels locale="DVB" ></Channels>', "text/xml");
  const channelsElement = xmlDoc.documentElement;
  for (let i = 0; i < channelInfoList.length; i++) {
    if (channelInfoList[i].tvRadio === "Radio") {continue;}

    const channelElement = pcdXmlAppendChild(xmlDoc, channelsElement, "Channel");

    const chType = channelInfoList[i].type;
    if ((chType === "RF-Terrestrial") | (chType === "RF-Cable")) channelElement.setAttribute("type", "RF-DVB");
    else if (chType === "Satellite") channelElement.setAttribute("type", "DVB-S2");
    else if ((chType === "IP-UDP") | (chType === "IP-RTP")) channelElement.setAttribute("type", "IP");

    const logicalDataElement = pcdXmlAppendChild(xmlDoc, channelElement, "LogicalData");
    logicalDataElement.setAttribute("logicalChannel", channelInfoList[i].number);

    if (chType === "RF-Terrestrial") logicalDataElement.setAttribute("streamType", "16");
    else if (chType === "RF-Cable") logicalDataElement.setAttribute("streamType", "48");
    else if (chType === "Satellite") logicalDataElement.setAttribute("streamType", "undefined");
    else if (chType === "IP-UDP") logicalDataElement.setAttribute("streamType", "16");
    else if (chType === "IP-RTP") logicalDataElement.setAttribute("streamType", "32");
    logicalDataElement.setAttribute("listingID", "");
    logicalDataElement.setAttribute("icon", "");
    if (server === "PCA") logicalDataElement.setAttribute("label", channelInfoList[i].name === " " ? "No Name" : channelInfoList[i].name);
    else logicalDataElement.setAttribute("label", channelInfoList[i].name === " " ? "No Name" : encodeURI(channelInfoList[i].name));
    // logicalDataElement.setAttribute("label", channelInfoList[i].name.replace(/\s/g, "+"));
    logicalDataElement.setAttribute("enabled", "true");
    logicalDataElement.setAttribute("startChannel", "false");
    logicalDataElement.setAttribute("osd", "false");
    logicalDataElement.setAttribute("mediaType", "1");
    logicalDataElement.setAttribute("encrypted", channelInfoList[i].encrypt);
    logicalDataElement.setAttribute("category", "None");

    if ((chType === "RF-Terrestrial") | (chType === "RF-Cable")) {
      const dvbDataElement = pcdXmlAppendChild(xmlDoc, channelElement, "DVBData");
      dvbDataElement.setAttribute("freq", channelInfoList[i].frequency * 1000000);
      dvbDataElement.setAttribute("serviceID", channelInfoList[i].service_id);
      dvbDataElement.setAttribute("audioType", "-1");
    } else if (chType === "Satellite") {
      const dvbDataElement = pcdXmlAppendChild(xmlDoc, channelElement, "SatelliteData");
      dvbDataElement.setAttribute("frequency", channelInfoList[i].frequency);
      dvbDataElement.setAttribute("satelliteID", channelInfoList[i].satellite_id);
      dvbDataElement.setAttribute("programNumber", channelInfoList[i].service_id);
      dvbDataElement.setAttribute("polarization", (channelInfoList[i].polarisation === "V")? 1:2);
      dvbDataElement.setAttribute("symbolRate", channelInfoList[i].symbolRate);
    } else if ((chType === "IP-UDP") | (chType === "IP-RTP")) {
      const ipDataElement = pcdXmlAppendChild(xmlDoc, channelElement, "IPData");
      ipDataElement.setAttribute("ipAddr", channelInfoList[i].ipAddr);
      ipDataElement.setAttribute("ipPort", channelInfoList[i].ipPort);
      ipDataElement.setAttribute("program", channelInfoList[i].service_id);
      ipDataElement.setAttribute("pcrPid", "-1");
      ipDataElement.setAttribute("videoPid", "-1");
      ipDataElement.setAttribute("audioPid", "-1");
      ipDataElement.setAttribute("videoType", "-1");
      ipDataElement.setAttribute("audioType", "-1");
    }
    /* pcdXmlAppendChild(xmlDoc, channelElement, "ChannelLanguage").setAttribute(
      "languageCode",
      "en_US"
    ); */
  }

  const xmlSerializer = new XMLSerializer();
  const xmlOutput = xmlSerializer.serializeToString(xmlDoc);

  // console.log("xmlOutput:", xmlOutput);
  const file = new File([xmlOutput], "converted.xml", {
    type: "text/xml;charset=utf-8"
  });
  FileSaver.saveAs(file, "converted.xml", true);
};

export default PcdXmlGenerate;

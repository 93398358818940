const ChannelNameParcer = (hexVchName, vchName) => {
    let str = "";
    if (hexVchName.length !== 0) {
        str = HexVchName2VchName(hexVchName[0].childNodes[0].nodeValue);
    } else if (vchName[0].childNodes.length !== 0) {
        str = vchName[0].childNodes[0].nodeValue;
        //var str = "佇䥌䅃吠V";
        let bytesv2 = []; // char codes

        for (let i = 0; i < str.length; ++i) {
        let code = str.charCodeAt(i);
        bytesv2 = bytesv2.concat([code & 0xff, (code / 256) >>> 0]);
        }
        //console.log('bytesv2', bytesv2.join(', '));

        let result = "";
        for (let i = 0; i < bytesv2.length; ++i) {
        result += bytesv2[i] > 31 ? String.fromCharCode(bytesv2[i]) : "";
        }
        str = result;
        //console.log(result);
    }
    return str;
}
const HexVchName2VchName = (hexVchName) => {
    //var hex = "525349204c412031"; // ASCII HEX: 37="7", 57="W", 71="q"
    let str = '';
    for (let n = 0; n < hexVchName.length; n += 2) {
        const dec = parseInt(hexVchName.substr(n, 2), 16);
        str += (dec >= 32 && dec < 127)? String.fromCharCode(dec):"";
    }
    return str;
}

const VchName2HexVchName = (vchName) => {
    //var str = "佇䥌䅃吠V";
    let str = '';
    for (let i = 0; i < vchName.length; i++) {
    str = str.concat(vchName.charCodeAt(i).toString(16));
    }
    return str;
}
export {ChannelNameParcer, HexVchName2VchName, VchName2HexVchName};
// import uniqid from "uniqid";

const ExtractChannelListFromNewTll = (xmlDoc) => {
  if (xmlDoc.getElementsByTagName("legacybroadcast").length !== 0) {
    const webOS50Json = JSON.parse(xmlDoc.getElementsByTagName("legacybroadcast")[0].textContent);
    const clist = webOS50Json.channelList;
    const satList = webOS50Json.satelliteList;
    //console.log(clist[0]);
    const channelList = clist
      .filter((ch) => {
        if (ch.sourceIndex !== "HIPTV") {
          if (ch.deleted === true || ch.disabled === true || ch.skipped === true || ch.Invisible === true || ch.discarded === true || ch.locked === true) return false; // skip
        }
        return true;
      })
      .map((ch, index) => {
        if (ch.sourceIndex === "HIPTV") {
          const iptvChannel = {
            ...ch,
            id: index,
            number: ch.channelLogicalNum,
            tvRadio: "TV",
            name: ch.channelName,
            type: ch.protocolType === "udp" ? "IP-UDP" : "IP-RTP",
            ipAddr: ch.ipAddress,
            ipPort: ch.ipPort,
            service_id: ch.SVCID,
            encrypt: ch.bEncryptionch ? "Scramble" : "Free",
            // detail: `IP:Port ${ch.ipAddress}:${ch.ipPort}, ServiceID: ${ch.SVCID}`,
            frequency: "N/A",
            symbolRate: "N/A",
            polarisation: "N/A",
          };
          return iptvChannel;
        } else if (ch.sourceIndex === "SATELLITE DIGITAL") {
          const sat = satList.find((item) => item.satelliteId === ch.satelliteId);
          const tp = sat.TransponderList.find((item) => item.frequency === ch.frequency);
          const dtvChannel = {
            ...ch,
            id: index,
            number: ch.majorNumber & 0x3FFF,
            tvRadio: ch.majorNumber & 0x4000 ? "Radio" : "TV",
            name: ch.channelName,
            type: "Satellite",
            //frequency: ch.frequency,
            encrypt: ch.bEncryptionch ? "Scramble" : "Free",
            service_id: ch.SVCID,
            //satellite_id: ch.satelliteId,
            ipAddr: "N/A",
            ipPort: "N/A",
            symbolRate: tp.symbolRate,
            polarisation: tp.polarization === "Vertical" ? "V" : "H",
          };
          return dtvChannel;
        } else {
          //if ((ch.sourceIndex === "ANTENNA DIGITAL") || (ch.sourceIndex == "CABLE DIGITAL")){
          const dtvChannel = {
            ...ch,
            id: index,
            number: ch.majorNumber,
            tvRadio: ch.majorNumber & 0x4000 ? "Radio" : "TV",
            name: ch.channelName,
            type: ch.sourceIndex === "ANTENNA DIGITAL" ? "RF-Terrestrial" : "RF-Cable",
            encrypt: ch.bEncryptionch ? "Scramble" : "Free",
            frequency: ch.frequency / 1000,
            service_id: ch.SVCID,
            ipAddr: "N/A",
            ipPort: "N/A",
            symbolRate: "N/A",
            polarisation: "N/A",
          };
          return dtvChannel;
        }
      });
    return channelList;
  } else {
    return new Error("Not webOS 5.0 structure");
  }
};

export default ExtractChannelListFromNewTll;

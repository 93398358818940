import React, { useState } from "react";
import TllConverter from "./pages/tllConverter";
import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";

import "./App.css";

const App = () => {
  // React States
  const [errorMessages, setErrorMessages] = useState({});
  const [isloggedin, setLogin] = useState(false);

  // User Login info
  const database =
    process.env.NODE_ENV !== "production"
      ? [
          {
            username: "a",
            password: "a",
          },
        ]
      : [
          {
            username: "procentric",
            password: "Password4Partners",
          },
          {
            username: "user2",
            password: "pass2",
          },
        ];

  const errors = {
    uname: "invalid username",
    pass: "invalid password",
  };

  const handleSubmit = (event) => {
    //Prevent page reload
    event.preventDefault();

    var { uname, pass } = document.forms[0];

    // Find user login info
    const userData = database.find((user) => user.username === uname.value);

    // Compare user info
    if (userData) {
      if (userData.password !== pass.value) {
        // Invalid password
        setErrorMessages({ name: "pass", message: errors.pass });
      } else {
        setLogin(true);
      }
    } else {
      // Username not found
      setErrorMessages({ name: "uname", message: errors.uname });
    }
  };

  // Generate JSX code for error message
  const renderErrorMessage = (name) => name === errorMessages.name && <div className="error">{errorMessages.message}</div>;

  const handleLogout = (isLogout) => setLogin(false);

  // JSX code for login form
  const renderForm = (
    <div className="loginPage">
      <div className="loginBox">
        <img className="loginLogo" src="logo_procentric_black.svg" alt="LG ProCentric" />
        <div className="loginTitle">TLL Converter by DSL</div>
        <form onSubmit={handleSubmit}>
          <div className="loginItem">
            {/* <label className="loginKey">Username </label> */}
            <input className="loginValue" type="text" name="uname" required placeholder="User name " />
            {renderErrorMessage("uname")}
          </div>
          <div className="loginItem">
            {/* <label className="loginKey">Password </label> */}
            <input className="loginValue" type="password" name="pass" required placeholder="Password" />
            {renderErrorMessage("pass")}
          </div>
          <div className="loginButton">
            <input className="loginButton2" type="submit" value="Log In" />
          </div>
        </form>
      </div>
    </div>
  );

  return (
    <Authenticator className="loginPage">
      {({ signOut, user }) => (
        <div className="App">
          <TllConverter isloggedin={isloggedin} onLogout={signOut} />{" "}
        </div>
      )}
    </Authenticator>
  );
};

export default App;

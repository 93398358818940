import {registeredNonWebOSModelName} from './Common';

const ExtractTVConfig = (xmlDoc) => {

  const tvConfig = {};
  tvConfig.modelName = xmlDoc.getElementsByTagName("ModelName")[0].childNodes[0].nodeValue;
  
  // if(registeredNonWebOSModelName.findIndex(model => model.value === tvConfig.modelName.replace(/^[0-9][0-9]/g, '')) === -1){ //if TLL file is not from non-webOS model
  if(registeredNonWebOSModelName.findIndex(model => model.value === tvConfig.modelName) === -1){ //if TLL file is not from non-webOS model
    tvConfig.country = xmlDoc.getElementsByTagName("BroadcastCountrySetting").length !== 0 ? xmlDoc.getElementsByTagName("BroadcastCountrySetting")[0].childNodes[0].nodeValue : "EUU";
    if(xmlDoc.getElementsByTagName("enableHotelMode")[0]) {
      /* Hotel mode */
      tvConfig.hotelMode = xmlDoc.getElementsByTagName("enableHotelMode")[0].childNodes[0].nodeValue; // off : on
      tvConfig.powerOnStatus = xmlDoc.getElementsByTagName("powerOnStatus")[0].childNodes[0].nodeValue; // stand_by : lst : power_on
      tvConfig.enablePowerOnDefaultVolume = xmlDoc.getElementsByTagName("enablePowerOnDefaultVolume")[0].childNodes[0].nodeValue; // off : on
      tvConfig.enableStartVolume = xmlDoc.getElementsByTagName("enableStartVolume")[0].childNodes[0].nodeValue; // off : on
      tvConfig.powerOnDefaultVolume = xmlDoc.getElementsByTagName("powerOnDefaultVolume")[0].childNodes[0].nodeValue; // 0 - 100
      tvConfig.minimumVolume = xmlDoc.getElementsByTagName("minimumVolume")[0].childNodes[0].nodeValue; // 0 : 0 - 100
      tvConfig.maximumVolume = xmlDoc.getElementsByTagName("maximumVolume")[0].childNodes[0].nodeValue; // 0: 0 - 100
    
      /* Key Management */
      tvConfig.keyManagement = xmlDoc.getElementsByTagName("keyManagement")[0].childNodes[0].nodeValue; // off : on
      tvConfig.enableIrRemote = xmlDoc.getElementsByTagName("enableIrRemote")[0].childNodes[0].nodeValue; // normal : usePwrOnly : blockAll
      tvConfig.enableLocalKey = xmlDoc.getElementsByTagName("enableLocalKey")[0].childNodes[0].nodeValue; // normal : usePwrOnly : blockAll
    
      /* Limited mode */
      tvConfig.enableLimitedMode = xmlDoc.getElementsByTagName("enableLimitedMode")[0].childNodes[0].nodeValue; // off : on  
      tvConfig.enterStationMenu = xmlDoc.getElementsByTagName("enterStationMenu")[0].childNodes[0].nodeValue; // off : on  
      tvConfig.enableProgramChange = xmlDoc.getElementsByTagName("enableProgramChange")[0].childNodes[0].nodeValue; // off : on  
      tvConfig.enableMenuKey = xmlDoc.getElementsByTagName("enableMenuKey")[0].childNodes[0].nodeValue; // off : on  
      tvConfig.enableOsdDisplay = xmlDoc.getElementsByTagName("enableOsdDisplay")[0].childNodes[0].nodeValue; // off : on  
      tvConfig.enableSystemProviderMode = xmlDoc.getElementsByTagName("enableSystemProviderMode")[0].childNodes[0].nodeValue; // off : on  
      
      /* Commercial Settings */
      tvConfig.instantOn = xmlDoc.getElementsByTagName("instantOn")[0].childNodes[0].nodeValue; // enable: disable
      tvConfig.acOn = xmlDoc.getElementsByTagName("acOn")[0].childNodes[0].nodeValue; // 0 (off) : 2 (reboot) : 3 (mute)
    }
  }
  
  return tvConfig;
};

export default ExtractTVConfig;

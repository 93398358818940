const countryOptions = [
  { key: "al", value: "ALB", flag: "al", label: "Albania" },
  { key: "ad", value: "AND", flag: "ad", label: "Andorra" },
  { key: "at", value: "AUT", flag: "at", label: "Austria" },
  { key: "by", value: "BLR", flag: "by", label: "Belarus" },
  { key: "be", value: "BEL", flag: "be", label: "Belgium" },
  { key: "ba", value: "BIH", flag: "ba", label: "Bosnia and Herzegovina" },
  { key: "bg", value: "BGR", flag: "bg", label: "Bulgaria" },
  { key: "hr", value: "HRV", flag: "hr", label: "Croatia" },
  { key: "cy", value: "CYP", flag: "cy", label: "Cyprus" },
  { key: "cz", value: "CZE", flag: "cz", label: "Czech Republic" },
  { key: "dk", value: "DNK", flag: "dk", label: "Denmark" },
  { key: "ee", value: "EST", flag: "ee", label: "Estonia" },
  { key: "fo", value: "FRO", flag: "fo", label: "Faroe Islands" },
  { key: "fi", value: "FIN", flag: "fi", label: "Finland" },
  { key: "fr", value: "FRA", flag: "fr", label: "France" },
  { key: "de", value: "DEU", flag: "de", label: "Germany" },
  { key: "gi", value: "GIB", flag: "gi", label: "Gibraltar" },
  { key: "gr", value: "GRC", flag: "gr", label: "Greece" },
  { key: "hu", value: "HUN", flag: "hu", label: "Hungary" },
  { key: "is", value: "ISL", flag: "is", label: "Iceland" },
  { key: "ie", value: "IRL", flag: "ie", label: "Ireland" },
  { key: "it", value: "ITA", flag: "it", label: "Italy" },
  { key: "lv", value: "LVA", flag: "lv", label: "Latvia" },
  { key: "li", value: "LIE", flag: "li", label: "Liechtenstein" },
  { key: "lt", value: "LTU", flag: "lt", label: "Lithuania" },
  { key: "lu", value: "LUX", flag: "lu", label: "Luxembourg" },
  { key: "mk", value: "MKD", flag: "mk", label: "Macedonia" },
  { key: "mt", value: "MLT", flag: "mt", label: "Malta" },
  { key: "md", value: "MDA", flag: "md", label: "Moldova" },
  { key: "mc", value: "MCO", flag: "mc", label: "Monaco" },
  { key: "me", value: "MNE", flag: "me", label: "Montenegro" },
  { key: "nl", value: "NLD", flag: "nl", label: "Netherlands" },
  { key: "no", value: "NOR", flag: "no", label: "Norway" },
  { key: "pl", value: "POL", flag: "pl", label: "Poland" },
  { key: "pt", value: "PRT", flag: "pt", label: "Portugal" },
  { key: "ro", value: "ROU", flag: "ro", label: "Romania" },
  { key: "ru", value: "RUS", flag: "ru", label: "Russia" },
  { key: "sm", value: "SMR", flag: "sm", label: "San Marino" },
  { key: "rs", value: "SRB", flag: "rs", label: "Serbia" },
  { key: "sk", value: "SVK", flag: "sk", label: "Slovakia" },
  { key: "si", value: "SVN", flag: "si", label: "Slovenia" },
  { key: "es", value: "ESP", flag: "es", label: "Spain" },
  { key: "se", value: "SWE", flag: "se", label: "Sweden" },
  { key: "ch", value: "CHE", flag: "ch", label: "Switzerland" },
  { key: "ua", value: "UKR", flag: "ua", label: "Ukraine" },
  { key: "uk", value: "GBR", flag: "gb", label: "United Kingdom" },
  { key: "va", value: "VAT", flag: "va", label: "Vatican city" },
  { key: "eu", value: "EUU", flag: "eu", label: "European Union" },
];

const modelInch = [
  { key: "24", value: "24", text: "24" },
  { key: "28", value: "28", text: "28" },
  { key: "32", value: "32", text: "32" },
  { key: "40", value: "40", text: "40" },
  { key: "43", value: "43", text: "43" },
  { key: "49", value: "49", text: "49" },
  { key: "55", value: "55", text: "55" },
  { key: "60", value: "60", text: "60" },
  { key: "65", value: "65", text: "65" },
  { key: "70", value: "70", text: "70" },
  { key: "75", value: "75", text: "75" },
];

const registeredNewModelName = [
  { key: "Unknown", value: "Unknown", label: "Unknown" },
  { key: "65UM767H0LC", value: "65UM767H0LC", label: "65UM767H0LC" },
  { key: "55UM767H0LC", value: "55UM767H0LC", label: "55UM767H0LC" },
  { key: "50UM767H0LC", value: "50UM767H0LC", label: "50UM767H0LC" },
  { key: "43UM767H0LC", value: "43UM767H0LC", label: "43UM767H0LC" },
  { key: "75UM662H4LC", value: "75UM662H4LC", label: "75UM662H4LC" },
  { key: "65UM662H4LC", value: "65UM662H4LC", label: "65UM662H4LC" },
  { key: "55UM662H4LC", value: "55UM662H4LC", label: "55UM662H4LC" },
  { key: "50UM662H4LC", value: "50UM662H4LC", label: "50UM662H4LC" },
  { key: "43UM662H4LC", value: "43UM662H4LC", label: "43UM662H4LC" },
  { key: "75UM662H0LC", value: "75UM662H0LC", label: "75UM662H0LC" },
  { key: "65UM662H0LC", value: "65UM662H0LC", label: "65UM662H0LC" },
  { key: "55UM662H0LC", value: "55UM662H0LC", label: "55UM662H0LC" },
  { key: "50UM662H0LC", value: "50UM662H0LC", label: "50UM662H0LC" },
  { key: "43UM662H0LC", value: "43UM662H0LC", label: "43UM662H0LC" },
  { key: "65WM960H0LD", value: "65WM960H0LD", label: "65WM960H0LD" },
  { key: "55WM960H0LD", value: "55WM960H0LD", label: "55WM960H0LD" },
  { key: "65AM960H0LD", value: "65AM960H0LD", label: "65AM960H0LD" },
  { key: "55AM960H0LD", value: "55AM960H0LD", label: "55AM960H0LD" },
  { key: "48AM960H0LD", value: "48AM960H0LD", label: "48AM960H0LD" },
  { key: "32LN661HBLA", value: "32LN661HBLA", label: "32LN661HBLA" },
  { key: "32LN661HBLA", value: "32LN661HBLA", label: "32LN661HBLA" },
  { key: "28LN661HBLA", value: "28LN661HBLA", label: "28LN661HBLA" },
  { key: "24LN661HBLA", value: "24LN661HBLA", label: "24LN661HBLA" },
  { key: "32LN662VBLB", value: "32LN662VBLB", label: "32LN662VBLB" },
  { key: "28LN662VBLB", value: "28LN662VBLB", label: "28LN662VBLB" },
  { key: "24LN662VBLB", value: "24LN662VBLB", label: "24LN662VBLB" },
  { key: "65AN960H0LD", value: "65AN960H0LD", label: "65AN960H0LD" },
  { key: "55AN960H0LD", value: "55AN960H0LD", label: "55AN960H0LD" },
  { key: "48AN960H0LD", value: "48AN960H0LD", label: "48AN960H0LD" },
  { key: "65UR767H0ZC", value: "65UR767H0ZC", label: "65UR767H0ZC" },
  { key: "55UR767H0ZC", value: "55UR767H0ZC", label: "55UR767H0ZC" },
  { key: "50UR767H0ZC", value: "50UR767H0ZC", label: "50UR767H0ZC" },
  { key: "75UR762H0ZC", value: "75UR762H0ZC", label: "75UR762H0ZC" },
  { key: "65UR762H0ZC", value: "65UR762H0ZC", label: "65UR762H0ZC" },
  { key: "55UR762H0ZC", value: "55UR762H0ZC", label: "55UR762H0ZC" },
  { key: "50UR762H0ZC", value: "50UR762H0ZC", label: "50UR762H0ZC" },
  { key: "75UR762H9ZC", value: "75UR762H9ZC", label: "75UR762H9ZC" },
  { key: "65UR762H9ZC", value: "65UR762H9ZC", label: "65UR762H9ZC" },
  { key: "55UR762H9ZC", value: "55UR762H9ZC", label: "55UR762H9ZC" },
  { key: "50UR762H9ZC", value: "50UR762H9ZC", label: "50UR762H9ZC" },
  { key: "43UR762H9ZC", value: "43UR762H9ZC", label: "43UR762H9ZC" },
  { key: "STB-6500-ZA", value: "STB-6500-ZA", label: "STB-6500-ZA" },
  { key: "65WS960H0ZD", value: "65WS960H0ZD", label: "65WS960H0ZD" },
  { key: "55WS960H0ZD", value: "55WS960H0ZD", label: "55WS960H0ZD" },
  { key: "65WS960H2ZD", value: "65WS960H2ZD", label: "65WS960H2ZD" },
  { key: "55WS960H2ZD", value: "55WS960H2ZD", label: "55WS960H2ZD" },
  { key: "48ES961H0ZD", value: "48ES961H0ZD", label: "48ES961H0ZD" },
  { key: "65US762H0ZC", value: "65US762H0ZC", label: "65US762H0ZC" },
  { key: "55US762H0ZC", value: "55US762H0ZC", label: "55US762H0ZC" },
  { key: "49US762H0ZC", value: "49US762H0ZC", label: "49US762H0ZC" },
  { key: "75US760H0ZD", value: "75US760H0ZD", label: "75US760H0ZD" },
  { key: "65US662H0ZC", value: "65US662H0ZC", label: "65US662H0ZC" },
  { key: "55US662H0ZC", value: "55US662H0ZC", label: "55US662H0ZC" },
  { key: "50US662H0ZC", value: "50US662H0ZC", label: "50US662H0ZC" },
  { key: "43US662H0ZC", value: "43US662H0ZC", label: "43US662H0ZC" },
  { key: "65US662H9ZC", value: "65US662H9ZC", label: "65US662H9ZC" },
  { key: "55US662H9ZC", value: "55US662H9ZC", label: "55US662H9ZC" },
  { key: "50US662H9ZC", value: "50US662H9ZC", label: "50US662H9ZC" },
  { key: "43US662H9ZC", value: "43US662H9ZC", label: "43US662H9ZC" },
];

const registeredOldModelName = [
  { key: "55US342H0ZC", value: "55US342H0ZC", label: "55US342H0ZC" },
  { key: "50US342H0ZC", value: "50US342H0ZC", label: "50US342H0ZC" },
  { key: "43US342H0ZC", value: "43US342H0ZC", label: "43US342H0ZC" },
  { key: "55US342H9ZC", value: "55US342H9ZC", label: "55US342H9ZC" },
  { key: "50US342H9ZC", value: "50US342H9ZC", label: "50US342H9ZC" },
  { key: "43US342H9ZC", value: "43US342H9ZC", label: "43US342H9ZC" },
  { key: "32LS341H0ZA", value: "32LS341H0ZA", label: "32LS341H0ZA" },
  { key: "32LS341H9ZA", value: "32LS341H9ZA", label: "32LS341H9ZA" },
  { key: "32LS662V0ZC", value: "32LS662V0ZC", label: "32LS662V0ZC" },
  { key: "32LS662V9ZC", value: "32LS662V9ZC", label: "32LS662V9ZC" },
  { key: "65WT980H0ZA", value: "65WT980H0ZA", label: "65WT980H0ZA" },
  { key: "65ET961H0ZA", value: "65ET961H0ZA", label: "65ET961H0ZA" },
  { key: "55ET961H0ZA", value: "55ET961H0ZA", label: "55ET961H0ZA" },
  { key: "43UT782H0ZA", value: "43UT782H0ZA", label: "43UT782H0ZA" },
  { key: "43UT782H9ZA", value: "43UT782H9ZA", label: "43UT782H9ZA" },
  { key: "43UT782H1ZA", value: "43UT782H1ZA", label: "43UT782H1ZA" },
  { key: "43UT782V2ZA", value: "43UT782V2ZA", label: "43UT782V2ZA" },
  { key: "43UT782V0ZC", value: "43UT782V0ZC", label: "43UT782V0ZC" },
  { key: "43UT782V9ZC", value: "43UT782V9ZC", label: "43UT782V9ZC" },
  { key: "55UT762H1ZA", value: "55UT762H1ZA", label: "55UT762H1ZA" },
  { key: "49UT762H1ZA", value: "49UT762H1ZA", label: "49UT762H1ZA" },
  { key: "65UT762V0ZC", value: "65UT762V0ZC", label: "65UT762V0ZC" },
  { key: "55UT762V0ZC", value: "55UT762V0ZC", label: "55UT762V0ZC" },
  { key: "49UT762V0ZC", value: "49UT762V0ZC", label: "49UT762V0ZC" },
  { key: "65UT761H0ZB", value: "65UT761H0ZB", label: "65UT761H0ZB" },
  { key: "55UT761H0ZA", value: "55UT761H0ZA", label: "55UT761H0ZA" },
  { key: "49UT761H0ZB", value: "49UT761H0ZB", label: "49UT761H0ZB" },
  { key: "55UT662H2ZC", value: "55UT662H2ZC", label: "55UT662H2ZC" },
  { key: "49UT662H2ZB", value: "49UT662H2ZB", label: "49UT662H2ZB" },
  { key: "43UT662H2ZC", value: "43UT662H2ZC", label: "43UT662H2ZC" },
  { key: "65UT662H0ZC", value: "65UT662H0ZC", label: "65UT662H0ZC" },
  { key: "55UT662H0ZC", value: "55UT662H0ZC", label: "55UT662H0ZC" },
  { key: "49UT662H0ZB", value: "49UT662H0ZB", label: "49UT662H0ZB" },
  { key: "43UT662H0ZC", value: "43UT662H0ZC", label: "43UT662H0ZC" },
  { key: "65UT661H0ZB", value: "65UT661H0ZB", label: "65UT661H0ZB" },
  { key: "55UT661H0ZA", value: "55UT661H0ZA", label: "55UT661H0ZA" },
  { key: "49UT661H0ZA", value: "49UT661H0ZA", label: "49UT661H0ZA" },
  { key: "43UT661H0ZA", value: "43UT661H0ZA", label: "43UT661H0ZA" },
  { key: "32LT661HBZA", value: "32LT661HBZA", label: "32LT661HBZA" },
  { key: "32LT661H9ZA", value: "32LT661H9ZA", label: "32LT661H9ZA" },
  { key: "28LT661HBZA", value: "28LT661HBZA", label: "28LT661HBZA" },
  { key: "24LT661HBZA", value: "24LT661HBZA", label: "24LT661HBZA" },
  { key: "75UT640S0ZA", value: "75UT640S0ZA", label: "75UT640S0ZA" },
  { key: "70UT640S0ZA", value: "70UT640S0ZA", label: "70UT640S0ZA" },
  { key: "65UT640S0ZA", value: "65UT640S0ZA", label: "65UT640S0ZA" },
  { key: "60UT640S0ZA", value: "60UT640S0ZA", label: "60UT640S0ZA" },
  { key: "55UT640S0ZA", value: "55UT640S0ZA", label: "55UT640S0ZA" },
  { key: "49UT640S0ZA", value: "49UT640S0ZA", label: "49UT640S0ZA" },
  { key: "43UT640S0ZA", value: "43UT640S0ZA", label: "43UT640S0ZA" },
  { key: "65WU960H-ZA", value: "65WU960H-ZA", label: "65WU960H-ZA" },
  { key: "65EU961H-ZA", value: "65EU961H-ZA", label: "65EU961H-ZA" },
  { key: "55EU961H-ZA", value: "55EU961H-ZA", label: "55EU961H-ZA" },
  { key: "65UU761H-ZA", value: "65UU761H-ZA", label: "65UU761H-ZA" },
  { key: "55UU761H-ZA", value: "55UU761H-ZA", label: "55UU761H-ZA" },
  { key: "49UU761H-ZA", value: "49UU761H-ZA", label: "49UU761H-ZA" },
  { key: "65UU662H-ZE", value: "65UU662H-ZE", label: "65UU662H-ZE" },
  { key: "55UU661H-ZA", value: "55UU661H-ZA", label: "55UU661H-ZA" },
  { key: "49UU661H-ZA", value: "49UU661H-ZA", label: "49UU661H-ZA" },
  { key: "43UU661H-ZA", value: "43UU661H-ZA", label: "43UU661H-ZA" },
  { key: "49LU661H-ZA", value: "49LU661H-ZA", label: "49LU661H-ZA" },
  { key: "43LU661H-ZA", value: "43LU661H-ZA", label: "43LU661H-ZA" },
  { key: "32LU661H-ZA", value: "32LU661H-ZA", label: "32LU661H-ZA" },
  { key: "15LU766A-ZA", value: "15LU766A-ZA", label: "15LU766A-ZA" },
  { key: "65EV960H-ZA", value: "65EV960H-ZA", label: "65EV960H-ZA" },
  { key: "65UV761H-ZA", value: "65UV761H-ZA", label: "65UV761H-ZA" },
  { key: "55UV761H-ZA", value: "55UV761H-ZA", label: "55UV761H-ZA" },
  { key: "49UV761H-ZA", value: "49UV761H-ZA", label: "49UV761H-ZA" },
  { key: "43UV761H-ZA", value: "43UV761H-ZA", label: "43UV761H-ZA" },
  { key: "55UV661H-ZA", value: "55UV661H-ZA", label: "55UV661H-ZA" },
  { key: "49UV661H-ZA", value: "49UV661H-ZA", label: "49UV661H-ZA" },
  { key: "43UV661H-ZA", value: "43UV661H-ZA", label: "43UV661H-ZA" },
  { key: "55LV761H-ZA", value: "55LV761H-ZA", label: "55LV761H-ZA" },
  { key: "49LV761H-ZA", value: "49LV761H-ZA", label: "49LV761H-ZA" },
  { key: "43LV761H-ZA", value: "43LV761H-ZA", label: "43LV761H-ZA" },
  { key: "32LV761H-ZA", value: "32LV761H-ZA", label: "32LV761H-ZA" },
  { key: "28LV761H-ZB", value: "28LV761H-ZB", label: "28LV761H-ZB" },
  { key: "24LV761H-ZB", value: "24LV761H-ZB", label: "24LV761H-ZB" },
  { key: "49LV661H-ZA", value: "49LV661H-ZA", label: "49LV661H-ZA" },
  { key: "43LV661H-ZA", value: "43LV661H-ZA", label: "43LV661H-ZA" },
  { key: "STB-5500-ZA", value: "STB-5500-ZA", label: "STB-5500-ZA" },
  { key: "65EW961H-ZA", value: "65EW961H-ZA", label: "65EW961H-ZA" },
  { key: "55EW961H-ZA", value: "55EW961H-ZA", label: "55EW961H-ZA" },
  { key: "65UW960H-ZA", value: "65UW960H-ZA", label: "65UW960H-ZA" },
  { key: "55UW960H-ZA", value: "55UW960H-ZA", label: "55UW960H-ZA" },
  { key: "49UW960H-ZA", value: "49UW960H-ZA", label: "49UW960H-ZA" },
  { key: "43UW950H-ZA", value: "43UW950H-ZA", label: "43UW950H-ZA" },
  { key: "55UW761H-ZA", value: "55UW761H-ZA", label: "55UW761H-ZA" },
  { key: "49UW761H-ZA", value: "49UW761H-ZA", label: "49UW761H-ZA" },
  { key: "43UW761H-ZA", value: "43UW761H-ZA", label: "43UW761H-ZA" },
  { key: "43LW641H-ZA", value: "43LW641H-ZA", label: "43LW641H-ZA" },
  { key: "32LW641H-ZA", value: "32LW641H-ZA", label: "32LW641H-ZA" },
  { key: "55LX761H-ZA", value: "55LX761H-ZA", label: "55LX761H-ZA" },
  { key: "49LX761H-ZA", value: "49LX761H-ZA", label: "49LX761H-ZA" },
  { key: "43LX761H-ZA", value: "43LX761H-ZA", label: "43LX761H-ZA" },
  { key: "40LX761H-ZA", value: "40LX761H-ZA", label: "40LX761H-ZA" },
  { key: "32LX761H-ZA", value: "32LX761H-ZA", label: "32LX761H-ZA" },
  { key: "60UX960H-ZA", value: "60UX960H-ZA", label: "60UX960H-ZA" },
  { key: "55UX960H-ZA", value: "55UX960H-ZA", label: "55UX960H-ZA" },
  { key: "49UX960H-ZA", value: "49UX960H-ZA", label: "49UX960H-ZA" },
  { key: "43LY760H-ZA", value: "43LY760H-ZA", label: "43LY760H-ZA" },
  { key: "43LY960H-ZA", value: "43LY960H-ZA", label: "43LY960H-ZA" },
];
const registeredNonWebOSModelName = [
    { key: "49LT341H0ZA", value: "49LT341H0ZA", label: "49LT341H0ZA" },
    { key: "43LT341H0ZA", value: "43LT341H0ZA", label: "43LT341H0ZA" },
    { key: "32LT341HBZA", value: "32LT341HBZA", label: "32LT341HBZA" },
    { key: "49LT341H9ZA", value: "49LT341H9ZA", label: "49LT341H9ZA" },
    { key: "43LT341H9ZA", value: "43LT341H9ZA", label: "43LT341H9ZA" },
    { key: "32LT341H9ZA", value: "32LT341H9ZA", label: "32LT341H9ZA" },
    { key: "49LT340C0ZB", value: "49LT340C0ZB", label: "49LT340C0ZB" },
    { key: "43LT340C0ZB", value: "43LT340C0ZB", label: "43LT340C0ZB" },
    { key: "32LT340CBZB", value: "32LT340CBZB", label: "32LT340CBZB" },
    { key: "28LT340CBZA", value: "28LT340CBZA", label: "28LT340CBZA" },
    { key: "49LU341H-ZA", value: "49LU341H-ZA", label: "49LU341H-ZA" },
    { key: "43LU341H-ZA", value: "43LU341H-ZA", label: "43LU341H-ZA" },
    { key: "32LU341H-ZA", value: "32LU341H-ZA", label: "32LU341H-ZA" },
    { key: "55LV541H-ZA", value: "55LV541H-ZA", label: "55LV541H-ZA" },
    { key: "49LV541H-ZA", value: "49LV541H-ZA", label: "49LV541H-ZA" },
    { key: "43LV541H-ZA", value: "43LV541H-ZA", label: "43LV541H-ZA" },
    { key: "32LV541H-ZA", value: "32LV541H-ZA", label: "32LV541H-ZA" },
    { key: "55LV341H-ZA", value: "55LV341H-ZA", label: "55LV341H-ZA" },
    { key: "49LV341H-ZA", value: "49LV341H-ZA", label: "49LV341H-ZA" },
    { key: "43LV341H-ZA", value: "43LV341H-ZA", label: "43LV341H-ZA" },
    { key: "32LV341H-ZA", value: "32LV341H-ZA", label: "32LV341H-ZA" },
    { key: "75UV341C-ZC", value: "75UV341C-ZC", label: "75UV341C-ZC" },
    { key: "65UV341C-ZC", value: "65UV341C-ZC", label: "65UV341C-ZC" },
    { key: "55LV340C-ZB", value: "55LV340C-ZB", label: "55LV340C-ZB" },
    { key: "49LV340C-ZB", value: "49LV340C-ZB", label: "49LV340C-ZB" },
    { key: "43LV340C-ZB", value: "43LV340C-ZB", label: "43LV340C-ZB" },
    { key: "32LV340C-ZB", value: "32LV340C-ZB", label: "32LV340C-ZB" },
    { key: "49LV300C-ZA", value: "49LV300C-ZA", label: "49LV300C-ZA" },
    { key: "43LV300C-ZA", value: "43LV300C-ZA", label: "43LV300C-ZA" },
    { key: "32LV300C-ZA", value: "32LV300C-ZA", label: "32LV300C-ZA" },
];

const modelSuffix = [
  { key: "ZA", value: "ZA", text: "ZA" },
  { key: "ZB", value: "ZB", text: "ZB" },
  { key: "ZD", value: "ZD", text: "ZD" },
];

const powerOnStatusOptions = [
   { key: "power_on", value: "power_on", label: "power_on" },
   { key: "stand_by", value: "stand_by", label: "stand_by" },
   { key: "lst", value: "lst", label: "lst" },
 ];

const enableLocalKeyOptions = [
   { key: "normal", value: "normal", label: "normal" },
   { key: "usePwrOnly", value: "usePwrOnly", label: "usePwrOnly" },
   { key: "blockAll", value: "blockAll", label: "blockAll" },
];

const instantOnOptions = [
   { key: "off", value: "off", label: "off" },
   { key: "Instant On Reboot", value: "Instant On Reboot", label: "Instant On Reboot" },
   { key: "Instant On Mute", value: "Instant On Mute", label: "Instant On Mute" },
];

export { countryOptions, modelInch, registeredOldModelName, registeredNewModelName, registeredNonWebOSModelName, modelSuffix, powerOnStatusOptions, enableLocalKeyOptions, instantOnOptions };

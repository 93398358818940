import React from "react";
import pkg from '../../package.json';

import "./Title.css";

function formatBytes(bytes,decimals) {
  if(bytes === 0) return '0 Bytes';
  var k = 1024,
      dm = decimals || 2,
      sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

const Title = (props) => {
  return (
    <div className="tllTitleBar">
      <div className="titleLogo">
        <img className="titleLogoImg" src="logo_procentric_black_crop.svg" alt="LG ProCentric" />
        <span className="titleText">TLL Converter</span>
        <p className="titleVersion">v{pkg.version}</p>
      </div>
      <div className="titleFileInfo">{props.fileName} - {formatBytes(props.fileSize, 3)}</div>
      <div className="titleButtons">
        <button className="titleCpls" onClick={() => window.location = "https://converters.europedsl.com/cpls"}>CPLS Converter</button>
        <button className="titleLogout" onClick={() => props.onLogout()}>
          <span className="titleLogoutText">Logout</span>
        </button>
      </div>
    </div>
  );
};

export default Title;
